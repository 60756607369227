const styles = () => ({
  maxWidthModal: {
    '@media (min-width:1024px)': {
      maxWidth: '100%',
      minWidth: '40em'
    }
  },

  toolbar: {
    padding: 0,
    marginBottom: '1rem',
    display: 'grid',
    marginTop: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateAreas: `
              'searchInput searchInput searchInput searchInput searchInput addButton'
            `,
      gridGap: '1%'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
              'searchInput searchInput searchInput'
              'addButton addButton addButton'
            `,
      gridGap: '1em'
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
              'searchInput searchInput'
              'addButton addButton'
            `,
      gridGap: '1em'
    }
  },

  search: { margin: '0 1rem 0 0' },

  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
