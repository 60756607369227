import React, { Fragment, useEffect } from 'react';

import { ADDITIONAL_FIELD } from 'helpers/constants';

import { useStyles } from './StylesDiploma';

const PreviewDiploma = ({ data, drawImageOnCanvas }) => {
  const classes = useStyles();

  useEffect(() => {
    drawImageOnCanvas();
  }, []);

  return (
    <div className={classes.outerCanvasWrapper}>
      <div id="canvasContainer" className={classes.canvasContainer}>
        <canvas
          id="canvas"
          style={{
            border: '1px solid rgba(137, 150, 172, 0.7)',
            borderRadius: '0.3rem'
          }}
        />
        {data?.textFields?.map((val, valIdx) => {
          return (
            <Fragment key={valIdx}>
              {val.params?.map((v, vIdx) => {
                const { name } = val;
                const {
                  previewLabel,
                  x,
                  y,
                  width,
                  fontSize,
                  fontFamily,
                  fontWeight,
                  fontAlign
                } = v;
                return (
                  <Fragment key={`${valIdx}_${vIdx}`}>
                    {v?.isActive && (
                      <div
                        key={name}
                        id={`preview_fields-${name}`}
                        style={{
                          position: 'absolute',
                          border: '1px solid',
                          overflowWrap: 'break-word',
                          left: `${x}px`,
                          top: `${y}px`,
                          width: `${width}px`,
                          fontSize: `${fontSize}px`,
                          fontFamily: fontFamily,
                          fontWeight: `${fontWeight}`,
                          textAlign: `${fontAlign}`
                        }}>
                        {previewLabel}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PreviewDiploma;
