const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxColumnWidth: {
    maxWidth: '20em',
    whiteSpace: 'unset !important'
  },

  column: {
    flexDirection: 'column'
  },

  bgWhite: { background: '#fff' },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  centerVertically: {
    alignItems: 'center'
  },

  selfAlignCenter: {
    alignSelf: 'center'
  },

  buttonsSize: {
    width: '1.5em',
    height: '1.5em'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  rowOrder: {
    borderRight: '1px solid #8996AC',
    minWidth: '2.063em'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  cardMainPart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  gridRecordsAttachments: {
    '@media (min-width:1025px)': {
      gridTemplateColumns: '25em 1fr'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '15em 1fr'
    }
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeft0dot4: {
    marginLeft: '0.4rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  cardMainName: {
    fontWeight: 500
  },

  checkboxesWrapper: {
    margin: '0 -5px 10px -5px',
    flexWrap: 'wrap'
  },

  checkboxItem: {
    margin: '10px 5px',
    '@media (max-width:900px)': {
      width: '8.7em'
    },
    '@media (min-width:901px)': {
      width: '11.5em'
    },
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  checkbox: {
    marginLeft: 'auto',
    marginRight: 0
  },

  disabledIc: {
    opacity: 0.4,
    pointerEvents: 'none'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  gridButtons: {
    display: 'grid',
    width: '100%',
    gridGap: '1rem',
    marginBottom: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(8, 1fr)',
      gridTemplateAreas: `
        'searchInput searchInput searchInput searchInput searchInput searchInput filterButton add'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'searchInput searchInput'
        'add filterButton'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'searchInput'
        'add'
        'filterButton'
      `
    }
  },

  borderBtn: {
    borderRadius: '0.2rem',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  selected: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },
  emptyState: {
    marginTop: '40px'
  }
});
export default styles;
