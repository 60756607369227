import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as DeleteIcon } from 'assets/img/delete_grey_bg&border.svg';
import { ReactComponent as EditIcon } from 'assets/img/edit_grey_bg&border.svg';
import { ReactComponent as Document } from 'assets/img/document_new.svg';
import { ReactComponent as Diploma } from 'assets/img/diploma_new.svg';

import { Tooltip } from '@material-ui/core';

import {
  DELETE_ICON,
  PREVIEW_DIPLOMA,
  DIPLOMA_TEST_ID as DIPLOMA,
  DOWNLOAD_DOCUMENTS_OPTIONS
} from '../../../helpers/constants';
import { DDMMYYYY, isMobileView } from '../../../helpers/selectors';

import Table from 'components/Table/CardsList';
import Button from 'components/Buttons/ActionButtons';

import { useStyles, useStylesRecords } from './TableListStyles';

const TableList = (props) => {
  const {
    page,
    rowsPerPage,
    selectedRow,
    sortDirection,
    onSort,
    filteredFiles,
    showModal,
    downloadUplaodedFile,
    shouldDisableEditing,
    viewportWidth
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = isMobileView(viewportWidth);

  const recordDataParams = (item, idx) => {
    return {
      selected: selectedRow === item.id,
      class: { gridRecords: classes.gridRecordsAttachments },
      main: {
        label: t('title'),
        name: item.name,
        showLabelName: !isMobile,
        hasSort: true,
        sort: 'name',
        style: {
          cardMainPart: classes.cardWrapper,
          recordName: classes.txtWrapper
        },
        rowOrder: {
          value: (page - 1) * rowsPerPage + idx + 1
        },
        hasPhoto: true,
        photo: item.photo,
        defaultImg: { ic: <Document className={classes.docIc} /> }
      },
      info: [
        {
          name: t('attachmentType'),
          value: DOWNLOAD_DOCUMENTS_OPTIONS.find((it) => it.type === item.type)
            ?.label,
          sort: 'type'
        },
        {
          name: t('uploadDate'),
          value: DDMMYYYY(item.uploading_date),
          sort: 'uploading_date'
        },
        {
          value: (
            <div className={clsx(classes.flex)}>
              <Tooltip arrow title={t('download')}>
                <Button
                  className={clsx(classes.onHover, classes.btnDownloadDoc)}
                  onClick={() => downloadUplaodedFile(item.id)}
                  label={t('download')}
                  isSaveBtn
                />
              </Tooltip>
            </div>
          ),
          className: classes.center
        },
        {
          value: (
            <div
              style={{
                display: 'grid',
                gap: '1rem',
                gridTemplateColumns: 'repeat(3, max-content)',
                alignItems: 'center'
              }}>
              {!shouldDisableEditing && (
                <>
                  <Tooltip arrow title={t('edit')}>
                    <EditIcon
                      className={clsx(classes.actionIcDoc, classes.onHover)}
                      onClick={(evt) => showModal(evt, item)}
                    />
                  </Tooltip>
                  <Tooltip
                    arrow
                    title={t('deleteRecord', { name: 'attachment' })}>
                    <DeleteIcon
                      id={DELETE_ICON}
                      className={clsx(classes.actionIcDoc, classes.onHover)}
                      onClick={(evt) => showModal(evt, item)}
                    />
                  </Tooltip>
                </>
              )}
              {+item?.tournament_file_type === 3 && (
                <Tooltip arrow title={t('diploma')}>
                  <Diploma
                    id={!shouldDisableEditing ? DIPLOMA : PREVIEW_DIPLOMA}
                    className={clsx(classes.actionIcDoc, classes.onHover)}
                    onClick={(evt) => showModal(evt, item)}
                  />
                </Tooltip>
              )}
            </div>
          )
        }
      ]
    };
  };

  const classes2 = useStylesRecords(recordDataParams({})?.info);

  return (
    <Table
      {...{ recordDataParams, sortDirection, onSort }}
      data={filteredFiles.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )}
      onClick={(evt, item) => showModal(evt, item)}
      specificDetails={{
        className: { gridRecordsValue: classes2.gridRecordsValue }
      }}
    />
  );
};

export default TableList;
