import { Component } from 'react';
import { withRouter } from '../../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import {
  fetchTournaments,
  getCertificates,
  uploadCertificate,
  changeModal,
  textChangeHandler,
  uploadFile,
  fetchUploadedFiles,
  downloadUplaodedFile,
  deleteUploadedFile,
  downloadFile,
  validateForm,
  pageChangeHandler,
  rowsPerPageHandler,
  changeHead,
  fetchUsers,
  fetchReference
} from '../../../helpers/util';
import {
  serverDefaultPath,
  DIPLOMA_TEST_ID as DIPLOMA,
  PREVIEW_DIPLOMA,
  FILE_DATA,
  DELETE_ICON,
  KEEP_EDITING,
  ADD_ADDITIONAL,
  INITIAL_ALL_FIELDS,
  CLOSE_DISCARD
} from '../../../helpers/constants';
import {
  formatDateMMDDYYYY,
  finishedTournament,
  resizeUploadedFile,
  findSortDirection,
  compareValueForSorting
} from '../../../helpers/selectors';

import { AuthContext } from 'AuthContext';

import SideModal from '../../../components/Snackbar/SideModal';
import Modal from '../../../components/Modal/ModalNewDesign';
import DropFile from '../../../components/CertificateBuilder/Dropzone/DropFile';
import LoadingState from '../../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../../components/HeaderTournInfo/HeaderTournInfo';
import Pagination from '../../../components/TablePagination/TablePagination';
import TableList from './TableList';
import EmptyState from '../../../components/EmptyState/EmptyState';
import Button from 'components/Buttons/ActionButtons';
import SetDiploma from 'components/CertificateBuilder/SetDiploma';
import PreviewDiploma from 'components/CertificateBuilder/PreviewDiploma';

import styles from './styles';
import { SearchInput } from 'components';

class Attachment extends Component {
  state = {
    tournamentId: +this.props.match.params.id,
    open: {},
    success: false,
    showModal: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    isModalOpened: false,
    fileData: { scaleX: 1, scaleY: 1 },
    fileDataErrors: {},
    errors: {},
    tab: 1,
    tournamentData: {},
    filteredFiles: [],
    statistics: {},
    uploadedFiles: [],
    sortDirection: [],
    page: 1,
    rowsPerPage: 25,
    filesCount: 0
  };

  fetchTournaments = fetchTournaments.bind(this);
  getCertificates = getCertificates.bind(this);
  uploadCertificate = uploadCertificate.bind(this);
  changeModal = changeModal.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  uploadFile = uploadFile.bind(this);
  fetchUploadedFiles = fetchUploadedFiles.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  deleteUploadedFile = deleteUploadedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  validateForm = validateForm.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  changeHead = changeHead.bind(this);
  fetchUsers = fetchUsers.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { open, fileData, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (Object.keys(open).length > 0) {
      document.addEventListener('keydown', this.onKeyPress);
    }

    if (isEditing && fileData && fileData !== prevState.fileData) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('attachments'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    const { tournamentId } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;

      this.setState({ shouldDisableEditing }, () => {
        this.fetchFiles(tournamentId);
      });

      changeHead(tournamentData, t('attachments'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'select-attachment'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  onFilterRecords = () => {
    const { uploadedFiles, searchBar } = this.state;
    const len = uploadedFiles?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = uploadedFiles[i];
      const bySearch = searchBar
        ? [el.name].some((val) =>
            val.toLowerCase().includes(searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredFiles: newFilteredRecords }, () => {
      const { filteredFiles } = this.state;
      const len = filteredFiles?.length;

      this.setState({ filesCount: len });
    });
  };

  tableSearch = (event) => {
    const { value } = event.target;

    this.setState(
      {
        searchBar: value,
        page: 1
      },
      () => {
        this.onFilterRecords();
      }
    );
  };

  clearSearch = () => {
    const { uploadedFiles } = this.state;
    const len = uploadedFiles?.length;

    this.setState({
      searchBar: '',
      filteredFiles: uploadedFiles,
      filesCount: len
    });
  };

  onKeyPress = (evt) => {
    if (evt.key === 'Escape') this.setState({ open: {} });
  };

  onFilterSetDiploma = (_, value, elem) => {
    const { fileData } = this.state;
    let cpy = { ...fileData };

    if (value !== null) {
      cpy.textFields[elem.itIdx].params[elem.additionalIdx][elem.arg] =
        value[elem.name];

      this.setState({
        fileData: cpy
      });
    }
  };

  hideSnackBar = () => this.setState({ showModal: false });
  onFilter = (_, value) => {
    const { fileDataErrors } = this.state;
    if (value !== null) {
      let allowUser = '';
      if (value.id === '2' || value.id === '3') {
        allowUser = value.id;
      }
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          tournament_file_type: value.id,
          type: value.type,
          allowUser
        }
      }));
      delete fileDataErrors.tournament_file_type;
    }
  };

  handleTournDownloadPermission = (evt) => {
    const { fileDataErrors } = this.state;
    const allowUser = evt.target.value;
    this.setState((prevState) => ({
      isEditing: true,
      fileData: {
        ...prevState.fileData,
        allowUser
      }
    }));
    delete fileDataErrors.allowUser;
  };

  showModal = async (evt, item) => {
    const { tournamentData } = this.state;
    const { t } = this.props;
    const targetID = item?.targetId || evt.currentTarget.id;
    const { id } = item || {};
    let modalData = {
      ...(item
        ? {
            ...item,
            tournament_file_type: item.tournament_file_type,
            attachment_name: item.name,
            id,
            type: item.type,
            allowUser:
              item.allow_all === 1
                ? '1'
                : item.allow_recorder_in === 1
                ? '2'
                : item.allow_coach_in === 1
                ? '3'
                : ''
          }
        : {})
    };

    if (targetID === DIPLOMA || targetID === PREVIEW_DIPLOMA) {
      try {
        const [userData, certificateData] = await Promise.all([
          this.fetchUserData(targetID),
          this.getCertificateData(tournamentData)
        ]);

        const template = certificateData?.[0];
        const textFields = this.generateTextFields(template, userData, t);

        modalData = {
          ...template,
          path: template?.file_name,
          textFields
        };
      } catch (error) {
        console.error('Error!', error);
      }
    }

    this.setState({
      fileData: modalData,
      pressedIcon: targetID,
      isModalOpened: true,
      selectedRow: id,
      ...(targetID === DELETE_ICON ? { fileId: id } : {})
    });
  };

  fetchUserData = async (targetID) => {
    if (targetID === PREVIEW_DIPLOMA) {
      return new Promise((resolve) => {
        this.fetchUsers(localStorage.getItem('user_id'), 'loggedUser', resolve);
      });
    }

    return {};
  };

  getCertificateData = async (tournamentData) => {
    return new Promise((resolve) => {
      this.getCertificates({ tournament_id: tournamentData?.id }, resolve);
    });
  };

  // generate text fields
  generateTextFields = (template, item, t) => {
    const allFields = INITIAL_ALL_FIELDS(t);

    return allFields.map((it) => {
      const fieldParams = this.getFieldParams(it, template, item);

      return {
        ...it,
        params: fieldParams
      };
    });
  };

  //  get field parameters
  getFieldParams = (it, template, item) => {
    const { t } = this.props;

    let finalParams = [];
    const fieldParam = (val) => ({
      default_fontSize: it.default_fontSize,
      default_width: it.default_width,
      label: val?.[4] || (it.name === 'additional_field' && it.label),
      x: val?.[0] ? parseInt(val[0], 10) : 0,
      y: val?.[1] ? parseInt(val[1], 10) : 0,
      fontSize: parseInt(val[2]),
      width: parseInt(val[3]),
      fontFamily: val?.[5]?.length > 0 ? val[5] : 'Helvetica',
      fontWeight: val?.[6] ?? 'normal',
      fontAlign: val?.[7] ?? 'center'
    });

    const setPreviewLabel = (recievedVal) => {
      const currentVal = it.name;

      switch (currentVal) {
        case 'name_field':
          return t('diplomaName');
        case 'date_field':
          return formatDateMMDDYYYY(new Date(), '/');
        case 'category_field':
          return t('diplomaCategory');
        case 'age_field':
          return '14-15';
        case 'weight_field':
          return '-75';
        case 'place_field':
          return '1';
        case 'region_field':
          return item['region'];
        case 'additional_field':
          return recievedVal ?? elem.label;
        default:
          return '';
      }
    };

    if (typeof template?.[it.name] === 'string') {
      const splitVal = template[it.name].split('|');
      const previewLabel = setPreviewLabel();

      finalParams.push({
        ...fieldParam(splitVal),
        previewLabel,
        isActive: template[it.name]?.length > 0
      });
    } else if (Array.isArray(template?.[it.name])) {
      finalParams = template[it.name].map((field) => {
        const splitVal = field.split('|');
        const previewLabel = setPreviewLabel(splitVal[4]);

        return { ...fieldParam(splitVal), previewLabel, isActive: true };
      });
    }

    return finalParams.length ? finalParams : [fieldParam({})];
  };

  hideModal = (elem) => {
    if (
      this.state.isAttemptingToEditModalFields &&
      elem?.targetID !== 'TEST_ID_CLOSE_IC'
    ) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        isModalOpened: false,
        selectedRow: null,
        fileData: {},
        fileDataErrors: {},
        isAttemptingToEditModalFields: false,
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  onDropHandler = (acceptedFiles) => {
    const { fileDataErrors } = this.state;
    delete fileDataErrors.files;

    const setNewFile = (newFile) => {
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          files: newFile
        }
      }));
    };

    if (acceptedFiles[0]) {
      if (acceptedFiles[0].type.includes('image/')) {
        resizeUploadedFile(acceptedFiles[0], (newFile) =>
          setNewFile([newFile])
        );
      } else {
        setNewFile(acceptedFiles);
      }
    }
  };
  removeFileHandler = () => {
    this.setState((prevState) => ({
      fileDataErrors: {
        ...prevState.fileDataErrors,
        files: this.props.t('required')
      },
      fileData: {
        ...prevState.fileData,
        files: null
      }
    }));
  };

  fetchFiles = (tournamentId) => {
    this.fetchUploadedFiles(null, tournamentId, null, () => {
      const { uploadedFiles } = this.state;
      const filteredFiles = [...uploadedFiles];
      const filesCount = uploadedFiles.length;
      const groupedByType = uploadedFiles.reduce((acc, currentVal) => {
        const key = currentVal.type;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(currentVal);

        return acc;
      }, {});
      const statistics = {
        totalCount: filesCount,
        documentsCount: groupedByType?.document?.length ?? 0,
        certificatesCount: groupedByType?.certificate?.length ?? 0
      };

      this.setState({ filteredFiles, filesCount, loading: false, statistics });
    });
  };

  addFile = (evt) => {
    const { fileData, tournamentId } = this.state;
    const requiredFields =
      fileData.tournament_file_type === '2'
        ? {
            ...(fileData.id ? {} : { files: '' }),
            tournament_file_type: '',
            allowUser: ''
          }
        : { ...(fileData.id ? {} : { files: '' }), tournament_file_type: '' };
    this.validateForm(evt, requiredFields, fileData, 'fileDataErrors', () => {
      this.uploadFile(evt, fileData, tournamentId, () => {
        this.fetchFiles(tournamentId);
        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      });
    });
  };
  deleteFile = (evt) => {
    const { fileId, tournamentId } = this.state;
    this.deleteUploadedFile(evt, fileId, () => {
      this.fetchFiles(tournamentId);
      this.hideModal();
    });
  };
  onFormEdit = (evt) => {
    const { errors } = this.state;
    this.textChangeHandler(
      evt,
      FILE_DATA,
      errors,
      null,
      null,
      null,
      null,
      null,
      () => {
        this.setState({ isEditing: true });
      }
    );
  };

  onAddTextField = (elem) => {
    const { fileData } = this.state;
    const { t } = this.props;
    let cpy = { ...fileData };

    if (elem?.targetID === ADD_ADDITIONAL) {
      const additional = INITIAL_ALL_FIELDS(t).pop();

      cpy.textFields[elem.itIdx].params = [
        ...cpy.textFields[elem.itIdx].params,
        {
          width: additional.default_width,
          fontSize: additional.default_fontSize, // in case value is not set, use default value
          x: 0,
          y: 0,
          fontFamily: 'Helvetica',
          fontWeight: 'normal',
          fontAlign: 'center',
          isActive: true,
          label: cpy.textFields[elem.itIdx]?.label
        }
      ];
    } else {
      if (cpy.textFields[elem.itIdx].params?.length > 1) {
        cpy.textFields[elem.itIdx].params.splice(elem.additionalIdx, 1);
      } else {
        cpy.textFields[elem.itIdx].params[elem.additionalIdx] = {
          ...cpy.textFields[elem.itIdx].params[elem.additionalIdx],
          label: null,
          ...(cpy.textFields[elem.itIdx].params[elem.additionalIdx].fontSize
            ? {}
            : {
                fontSize:
                  cpy.textFields[elem.itIdx].params[elem.additionalIdx]
                    .default_fontSize
              }),
          ...(cpy.textFields[elem.itIdx].params[elem.additionalIdx].width
            ? {}
            : {
                width:
                  cpy.textFields[elem.itIdx].params[elem.additionalIdx]
                    .default_width
              }),
          isActive:
            !cpy.textFields[elem.itIdx].params[elem.additionalIdx].isActive
        };
      }
    }

    this.setState({ fileData: cpy });
  };

  onUpdatePosition = (elem, x, y) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };

    cpy.textFields[elem.itIdx].params[elem.additionalIdx] = {
      ...cpy.textFields[elem.itIdx].params[elem.additionalIdx],
      x,
      y
    };

    this.setState({ fileData: cpy });
  };

  onUpdateSize = (elem, width) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };

    cpy.textFields[elem?.itIdx].params[elem.additionalIdx] = {
      ...cpy.textFields[elem.itIdx].params[elem.additionalIdx],
      width
    };

    this.setState({ fileData: cpy });
  };

  onMouseDown = (e, elem) => {
    const canvas = document.getElementById('canvas');
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (e.target.id === `resizeHandle-${elem?.name}`) {
      this.setState({
        isResizing: true,
        currentTextField: {
          ...elem,
          params: { ...elem.params, startX: mouseX, startY: mouseY }
        }
      });
    } else {
      this.setState({
        isDragging: true,
        currentTextField: {
          ...elem,
          params: {
            ...elem.params,
            startX: mouseX,
            startY: mouseY
          }
        }
      });
    }
  };

  onMouseMove = (e) => {
    const { isDragging, isResizing, currentTextField } = this.state;
    const canvas = document.getElementById('canvas');

    if (!canvas || !currentTextField) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (isDragging) {
      const newX =
        mouseX - currentTextField.params.startX + currentTextField.params.x;
      const newY =
        mouseY - currentTextField.params.startY + currentTextField.params.y;

      const boundariesX = Math.max(
        0,
        Math.min(newX, canvas.width - currentTextField.params.width)
      );
      const boundariesY = Math.max(
        0,
        Math.min(newY, canvas.height - currentTextField.params.fontSize)
      );

      this.onUpdatePosition(currentTextField, boundariesX, boundariesY);
    }

    if (isResizing) {
      const dx = mouseX - currentTextField.params.startX;
      //max size to fit canvas boundaries
      const boundariesWidth = Math.min(
        Math.max(10, currentTextField.params.width + dx),
        canvas.width - currentTextField.params.x
      );

      this.onUpdateSize(currentTextField, boundariesWidth);
    }
  };

  onMouseUp = () => {
    const { isDragging, isResizing } = this.state;

    if (isDragging || isResizing) {
      this.setState({
        isDragging: false,
        isResizing: false,
        currentTextField: {}
      });
    }
  };

  drawImageOnCanvas = () => {
    const { fileData } = this.state;
    let cpy = { ...fileData };
    const canvas = document.getElementById('canvas');
    const canvasContainer = document.getElementById('canvasContainer');

    if (canvas) {
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = serverDefaultPath + fileData?.path;

      image.onload = () => {
        const imgWidth = image.width;
        const imgHeight = image.height;
        const imgRotation = imgHeight > imgWidth;

        const realDiplomHeight = imgRotation ? 1122.24 : 793.92;
        const realDiplomWidth = imgRotation ? 793.92 : 1122.24;

        const realDiplomaAspectRatio = realDiplomWidth / realDiplomHeight;

        let canvasDiplomaWidth, canvasDiplomaHeight;

        if (imgRotation) {
          canvasDiplomaHeight = realDiplomWidth;
          canvasDiplomaWidth = realDiplomWidth * realDiplomaAspectRatio;
        } else {
          canvasDiplomaWidth = realDiplomHeight;
          canvasDiplomaHeight = realDiplomHeight / realDiplomaAspectRatio;
        }

        canvasContainer.style.width = `${canvasDiplomaWidth}px`;
        canvasContainer.style.height = `${canvasDiplomaHeight}px`;
        canvas.style.width = `${canvasDiplomaWidth}px`;
        canvas.style.height = `${canvasDiplomaHeight}px`;

        canvas.width = canvasDiplomaWidth;
        canvas.height = canvasDiplomaHeight;

        // Calculate scaling factor to match real diploma size
        const scaleX = canvasDiplomaWidth / realDiplomWidth;
        const scaleY = canvasDiplomaHeight / realDiplomHeight;

        const scale = Math.min(scaleX, scaleY);

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.drawImage(
          image,
          0,
          0,
          imgWidth,
          imgHeight,
          0,
          0,
          canvasDiplomaWidth,
          canvasDiplomaHeight
        );

        cpy.textFields = cpy.textFields.map((it) => {
          return {
            ...it,
            params: it?.params.map((v) => {
              return {
                ...v,
                x: v?.x * scaleX,
                y: v.y * scaleY,
                width: v?.width,
                fontSize: v?.fontSize * scale
              };
            })
          };
        });

        cpy = {
          ...cpy,
          scaleX,
          scaleY
        };

        this.setState({
          fileData: cpy
        });
      };
    }
  };

  onSaveDiploma = (evt) => {
    const { fileData, tournamentData } = this.state;

    this.uploadCertificate(
      evt,
      {
        ...fileData,
        tournament_id: tournamentData?.id
      },
      this.hideModal
    );
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.tableSearch(event);
    }
  };

  onChangeTxt = (evt, elem) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };
    const { name, value } = evt.target;

    cpy.textFields[elem?.itIdx].params[elem.additionalIdx][name] = value;

    this.setState({ fileData: cpy });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { uploadedFiles, sortDirection } = this.state;
    const clone = [...uploadedFiles];
    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, sortField, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        uploadedFiles: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      loading,
      isModalOpened,
      fileData,
      errors,
      pressedIcon,
      tournamentData,
      fileDataErrors,
      page,
      rowsPerPage,
      filteredFiles,
      statistics,
      selectedRow,
      filesCount,
      shouldDisableEditing,
      searchBar,
      sortDirection,
      shouldShowDiscardChanges
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    let onClick, dialogTitle, dialogContent, buttonPurpose;

    if (pressedIcon === DELETE_ICON) {
      onClick = (evt) => this.deleteFile(evt);
      dialogTitle = t('deleteAttachment');
      dialogContent = t('deleteDocumenMsg');
      buttonPurpose = t('deleteRecord', { name: '' });
    } else if (pressedIcon === PREVIEW_DIPLOMA) {
      dialogContent = fileData.file_name ? (
        <PreviewDiploma
          drawImageOnCanvas={this.drawImageOnCanvas}
          data={fileData}
          {...{ viewportWidth }}
          specificDetails={{}}
        />
      ) : (
        t('noTemplateForDiploma')
      );
    } else if (pressedIcon === DIPLOMA) {
      dialogContent = fileData.file_name ? (
        <SetDiploma
          drawImageOnCanvas={this.drawImageOnCanvas}
          onFilterSetDiploma={this.onFilterSetDiploma}
          onChangeTxt={this.onChangeTxt}
          onSaveDiploma={this.onSaveDiploma}
          data={fileData}
          onMouseDown={this.onMouseDown}
          onAddTextField={this.onAddTextField}
          showModal={(evt) => {
            this.showModal(evt, { ...fileData, targetId: PREVIEW_DIPLOMA });
          }}
          {...{ viewportWidth }}
        />
      ) : (
        t('noTemplateForDiploma')
      );

      onClick = undefined;
      dialogTitle = t('diplomaConstructor');
    } else {
      dialogTitle = fileData.id ? t('editAttachment') : t('addAttachment');
      buttonPurpose = fileData.id ? t('save') : t('addNew');
      dialogContent = (
        <DropFile
          changeTextHandler={this.onFormEdit}
          onDrop={this.onDropHandler}
          removeFile={this.removeFileHandler}
          {...{ errors }}
          {...{ fileData }}
          {...{ fileDataErrors }}
          selectOption={this.handleTournDownloadPermission}
          onFilter={this.onFilter}
          specificDetails={{
            ...(+fileData?.tournament_file_type === 3
              ? { accept: 'image/jpg, image/jpeg, image/png' }
              : {})
          }}
        />
      );
      onClick = (evt) => this.addFile(evt);
    }

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: t('documentsCount'),
          name: statistics?.totalCount
        },
        {
          label: t('regulations'),
          name: statistics?.documentsCount
        },
        { label: t('diplomas'), name: statistics?.certificatesCount }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
              shouldShowBtn
            />
            <Modal
              open={isModalOpened}
              close={this.hideModal}
              {...{ onClick, dialogTitle, dialogContent, buttonPurpose }}
              specificDetails={{
                className: {
                  ...(pressedIcon !== DELETE_ICON
                    ? { paper: classes.maxWidthModal }
                    : {})
                }
              }}
              {...{ shouldShowDiscardChanges }}
              discardOrKeepEditing={this.discardOrKeepEditing}
            />
            <span className={classes.toolbar}>
              <SearchInput
                search={searchBar}
                className={classes.search}
                clearSearch={this.clearSearch}
                isSearchInactive={!searchBar}
                onKeyPress={this.pressEnterHandler}
                style={{ gridArea: 'searchInput' }}
                textChange={this.tableSearch}
              />
              <Button
                onClick={this.showModal}
                label={t('addNew')}
                isSaveBtn={true}
                style={{ gridArea: 'addButton' }}
              />
            </span>
            {filteredFiles != 0 ? (
              <>
                <TableList
                  {...{
                    page,
                    rowsPerPage,
                    filteredFiles,
                    sortDirection,
                    selectedRow,
                    viewportWidth
                  }}
                  onSort={this.onSort}
                  showModal={this.showModal}
                  downloadUplaodedFile={this.downloadUplaodedFile}
                  shouldAllowEditing={() => !shouldDisableEditing}
                />

                <Pagination
                  count={filesCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Attachment)));
