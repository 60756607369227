import React, { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import {
  finishedTournament,
  categoryTypesPresentOnTournament
} from '../../helpers/selectors';
import {
  changeModal,
  fetchTournaments,
  fetchAllCategoryTypesV2,
  changeHead,
  fetchReference
} from '../../helpers/util';
import {
  DISTRIBUTE_PARTICIPANTS_TAB,
  REASSIGN_CATEGORIES_TAB,
  CATEGORIES_TAB,
  TATAMIS_TAB
} from 'helpers/constants';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import DragDrop from '../DragDrop/DragDrop';
import DistributeParticipantsByCategories from '../DistributeParticipantsByCategories/DistributeParticipantsByCategories';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import AddTatami from '../TournamentAddTatamis/TournamentAddTatamis';
import TournamentAddCategories from '../TournamentAddCategories/TournamentAddCategories';
import Button from 'components/Buttons/ActionButtons';

import styles from './styles';

class TournamentTatamisAndCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentId: +props.match.params.id,
      langOnLoad: localStorage.getItem('i18nextLng'),
      loading: true,
      role: localStorage.getItem('role'),
      tab:
        new URLSearchParams(props.location.search)?.get('tab') ?? TATAMIS_TAB,
      tournamentData: {},
      categoryTypes: [],
      shouldDisableEditing: false
    };

    this.changeModal = changeModal.bind(this);
    this.fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.changeHead = changeHead.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, role, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (location.search !== prevProps.location.search) {
      const currentTab = new URLSearchParams(location.search)?.get('tab');

      this.setState({ tab: currentTab });
    }

    if (langOnLoad !== currentLang) {
      this.fetchAllCategoryTypesV2(
        role === 'administrator' && {
          user_id: tournamentData?.organizer_id
        },
        (types) => {
          this.setState({
            ...(types ? { categoryTypes: types } : {})
          });
        }
      );

      changeHead(tournamentData, t('tatamiCategories'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { tournamentId, role, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;

      this.fetchAllCategoryTypesV2(
        role === 'administrator' && {
          user_id: tournamentData?.organizer_id
        },
        (types) => {
          this.setState({ categoryTypes: types });
        }
      );

      this.setState({ shouldDisableEditing, loading: false });

      changeHead(tournamentData, t('tatamiCategories'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'event-setting'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  switchTabs = (key) => {
    const { navigate, location } = this.props;
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('tab', key);
    navigate({ search: searchParams.toString() });
    this.setState({ tab: key });
  };

  hideSnackbar = () => this.setState({ showModal: false });

  onGetPageHeaderStatistics = (result) => {
    this.setState({ statistics: result });
  };

  render() {
    const {
      tournamentId,
      showModal,
      modalInfo,
      tab,
      shouldDisableEditing,
      success,
      loading,
      tournamentData,
      categoryTypes,
      statistics
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    const TABS = [
      { id: TATAMIS_TAB, label: t('assignTatami') },
      { id: CATEGORIES_TAB, label: t('assignCategories') },
      { id: REASSIGN_CATEGORIES_TAB, label: t('distributeCategories') },
      {
        id: DISTRIBUTE_PARTICIPANTS_TAB,
        label: t('distributeParticipantsByCategories')
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {!loading &&
          (tournamentData?.id ? (
            <>
              <HeaderTournInfo
                {...{ tournamentData }}
                pageHeaderStatistics={statistics}
                shouldShowBtn={true}
                {...{ viewportWidth }}
              />
              <div className={classes.buttons}>
                {TABS.map(({ id, label }, idx) => (
                  <Button
                    className={clsx(
                      classes.colorTxt,
                      tab === id ? classes.selected : classes.notSelected
                    )}
                    key={idx}
                    label={label}
                    onClick={() => this.switchTabs(id)}
                  />
                ))}
              </div>
              {tab === TATAMIS_TAB && (
                <AddTatami
                  {...{ tournamentData }}
                  {...{ tournamentId }}
                  {...{ shouldDisableEditing, tournamentData, viewportWidth }}
                  onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
                />
              )}
              {tab === CATEGORIES_TAB && categoryTypes && (
                <TournamentAddCategories
                  {...{ shouldDisableEditing }}
                  {...{ tournamentId }}
                  {...{ viewportWidth }}
                  {...{ categoryTypes }}
                  {...{ tournamentData }}
                  onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
                />
              )}
              {tab === REASSIGN_CATEGORIES_TAB && (
                <DragDrop
                  {...{ tournamentData }}
                  {...{ categoryTypes }}
                  {...{ shouldDisableEditing, viewportWidth }}
                  onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
                />
              )}
              {tab === DISTRIBUTE_PARTICIPANTS_TAB && (
                <DistributeParticipantsByCategories
                  {...{ categoryTypes }}
                  {...{ tournamentData }}
                  {...{ shouldDisableEditing, viewportWidth }}
                  onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
                />
              )}
            </>
          ) : (
            <EmptyState />
          ))}
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(TournamentTatamisAndCategories))
);
