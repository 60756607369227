export default {
  exit: 'der Ausgang',
  lang: 'die Sprache',
  english: 'Englisch',
  russian: 'Russisch',
  romanian: 'Rumänisch',
  german: 'Deutsch',
  templates: 'die Vorlagen',
  template: 'die Vorlage',
  subject: 'das Subjekt',
  here: 'hier',
  apply: 'Auftragen',
  proceed: 'Fortfahren',
  picture: 'das Bild',
  photo: 'das Foto',
  accountCreated: 'Konto aufgemacht!',
  accountFailToCreate: 'Konnte das Konto aktivieren nicht!',
  invitationListTitle: 'Liste der Zulassungen für die Registrierung',
  chooseFromList: 'Aus Liste auswählen',
  fail2AddUser:
    'Änderungen konnten nicht gespeichert werden. Ein Benutzer namens „{{name}}“ ist bereits im System vorhanden.',
  navigateTo: 'Navigieren zu',
  min: 'min',
  sec: 'sec',
  kg: 'kg',
  cm: 'cm',
  minute: 'Minute',
  minutes: 'Minuten',
  second: 'Sekunde',
  seconds: 'Sekunden',
  hour: 'Stunde',
  hours: 'Stunden',
  days: 'Tage',
  day: 'Tag',
  tournamentDay: 'Turniertag',
  years: 'Jahre',
  errorBoundary: 'Oh, nein! Etwas ist schief gelaufen',
  logout: 'Ausloggen',
  firstname: 'der Vorname',
  lastname: 'der Nachname',
  firstNameNational: 'Vorname lateinischer',
  lastNameNational: 'Nachname lateinisch',
  yourFirstName: 'Ihr Name',
  yourLastName: 'Ihr Nachname',
  yourPatronymic: 'Ihr Vatersname',
  coachFirstName: 'Name des Trainers',
  coachLastName: 'Nachname Trainer',
  patronymic: 'Vatersname',
  tournament: 'Das Turnier',
  logIn: 'Einloggen',
  activateAccount: 'Konto aktivieren',
  loginIssue: 'Können Sie in das System eingeben nicht?',
  username: 'der Benutzernamen',
  password: 'das Passwort',
  passwordChangedSuccessfully: 'das Passwort wurde erfolgreich geändert!',
  emailSent:
    'Eine E-Mail wurde an Sie gesendet, um Ihr Passwort zurückzusetzen',
  followInstructions: 'Befolgen Sie die Anweisungen im Brief.',
  tournaments: 'die Turnieren',
  categories: 'die Kategorien',
  categoriesForm: 'die Kategorien',
  deleteCategoryErrorsBcOfTourn: `Diese Kategorie kann nicht gelöscht werden, da sie in nachfolgenden Turnieren verwendet wird: {{name}}`,
  deleteGroupCategoryErrorsBcOfCategories: `Diese Gruppe kann nicht gelöscht werden, da sie über Kategorien verfügt`,
  participantsNum: 'Menge. Teilnehmer',
  participants: 'die Teilnehmer',
  participant: 'der Teilnehmer',
  athletes: 'die Athleten',
  athlete: 'der Athlet',
  athleteForm: 'die Athleten',
  coachForm: 'die Athleten',
  totalRegistrars: 'der Registrare',
  myTeam: 'Mein Team',
  myOrganisation: 'Mein Organisation',
  cluborFederationName: 'Verein oder Organisation',
  yourCluborFederationName: 'Ihr Verein oder Ihre Organisation',
  users: 'die Benutzer',
  logs: 'Logs',
  demoUsers: 'die Demo-Benutzer',
  expandList: 'Liste erweitern',
  total: 'Insgesamt',
  sum: 'Insgesamt',
  upcoming: 'Kommende',
  finished: 'Vergangenheit',
  planUpcomingEvents: 'Plan der kommenden Veranstaltungen',
  pastEvents: 'Vergangene Ereignisse',
  eventStatus: 'Ereignisstatus',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  archive: 'Archiv',
  showRed: 'Rot anzeigen',
  appointed: 'ernannt',
  filled: 'gefüllt',
  human: 'mann',
  categoriesTotal: 'Gesamtzahl der {{name}}-Kategorien',
  categoriesUsed: 'Verwendete Kategorien',
  redCategories: 'Rote Kategorien',
  addRecord: '{{name}} hinzufügen',
  boys: 'die Jungen',
  girls: 'die Mädchen',
  men: 'die Männer',
  women: 'die Frauen',
  femaleGender: 'weibliche Geschlecht',
  maleGender: 'Geschlecht des Ehemanns',
  moreApps: 'Apps Herunterladen',
  forDesktop: 'für die Desktop',
  forMobile: 'für die Mobile',
  downloadTournamentManagerInfo:
    'Desktop-App helfen Sie das Turnier zu halten. Laden Sie es jetzt herunter, um bereit zu sein, einen Wettbewerb zu organisieren!',
  downloadCasterInfo:
    'Eine spezielle Anwendung, die den Betrieb einer Anzeigetafel unter Windows OS emuliert. Gute Alternative zum Raspberry Pi. Verwenden Sie diese App, wenn Sie keinen Raspberry Pi-Minicomputer haben.',
  downloadCastManager:
    'Eine Anwendung zum Einrichten der Anzeigetafel und Verwalten der Beziehung zwischen dem Tatami-Operator und dem Bild auf dem Bildschirm. Mit dieser Anwendung ist die Einrichtung einfacher und bequemer!',
  downloadMobileInfo:
    'Das Herunterladen unserer Handy App ist jetzt verfügbar. Einfacher, bequemer und schneller! Laden Sie die App auf Ihr Telefon herunter, um über die neuesten Ereignisse auf dem Laufenden zu bleiben.',
  downloadDiploma:
    'Hierbei handelt es sich um ein Programm zum Drucken und Erstellen von Auszeichnungsdiplomen, Zertifikaten und Diplomen. Wenn Sie beim Turnier über einen Drucker verfügen, senden Sie die Diplome sofort aus dem Programm zum Ausdrucken, sobald die Endspiele beendet sind. Nutzen Sie diese Anwendung, um die Finalisten des Turniers zu belohnen!',
  availableFor: 'Verfügbar für',
  versionLTS: 'Empfohlen Für Die Meisten Benutzer',
  version: 'Version',
  current: 'Aktuell',
  certificates: 'das Zertifikat',
  certificate: 'das Zertifikat',
  document: 'Dokumentieren',
  uploadDoc: 'Zertifikate Dokument',
  addAttachment: 'Dokument hinzufügen',
  attachment: 'die Anlage',
  attachments: 'die Anhänge',
  documents: 'Dokumentation',
  documentsCount: 'Gesamtdokumente',
  diplomas: 'Diplome',
  regulations: 'Vorschriften',
  attachmentType: 'Art des Dokuments',
  supportedDocument: 'Unterstützte Formate',
  nameDocument: 'Titel des Dokuments',
  acceptRequest: 'Anfrage annehmen',
  rejectRequest: 'Anfrage ablehnen',
  uploadDate: 'Datum des Hochladens',
  additional: 'Zusätzlich',
  toView: 'Sicht',
  viewDetails: 'Details anzeigen',
  diplomaName: 'James Smith',
  diplomaCategory: 'Cadets 14-15 years -75 kg',
  diplomaConstructor: 'Diplom-Konstrukteur',
  saveDiploma: 'Vorlage speichern',
  noTemplateForDiploma: 'Es gibt keine Vorlage für das Diplom.',
  judgeWithSuchPositionExists:
    'Richter „{{name1}}“ wurde bereits für die Position „{{position}}“ ernannt. Möchten Sie es durch Richter „{{name2}}“ ersetzen?',
  judicialQualifications:
    'Dokumente, die die gerichtliche Qualifikation bestätigen',
  failed2Load:
    'Datei konnte nicht hochgeladen werden, es liegen keine Rechte vor oder die Datei ist beschädigt',
  ikoQual: 'IKO-Qualifikation',
  rfQual: 'Kategorie',
  import: 'importieren',
  noAttachmentsMsg:
    'Es sieht so aus, als gäbe es keine Anhänge zum Herunterladen',
  noCertificates: 'Sie haben keine Zertifikate jezt.',
  certificateNameLimitExceed:
    'Der Titel des Dokuments darf 128 Zeichen nicht überschreiten.',
  informativeUploadImageMsg:
    'Um Anhänge erfolgreich zu importieren, verwenden Sie bitte .png, .jpg, .jpeg.',
  dragMsg: 'Oder ziehen Sie das Dokument per Drag & Drop',
  rejectedFileError:
    'Dieser Anhang hat das erforderliche Format nicht. Bitte laden Sie einen Anhang mit dem richtigen format.',
  browse: 'Durchsuchen',
  isDragRejectMsg:
    'Diese Datei kann nicht angehängt werden, denn das Format wird unterstützt nicht',
  certificateNameLabel: 'Der Zertifikatsname',
  fileTooLargeMsg:
    'Dieser Anhang überschreitet 20 MB. Bitte laden Sie einen kleineren Anhang hoch.',
  imgTooBig:
    'Die Befestigungsgröße überschreitet die zulässige Grenze, 3840x3840',
  attachment2Large: 'Der Anhang ist zu groß zum Hochladen.',
  maxChars: 'Max {{max}} Zeichen',
  minChars: 'Mindestens {{num}} Zeichen',
  dropFile: 'Drop die Datei zum Anhängen',
  remove: 'Entfernen',
  insufficientData: 'Unzureichende Daten',
  successfullyAdded: 'Erfolgreich hinzugefügt',
  resolveAllInputs: 'Auflösen Sie Alle Eingaben',
  successfullyUpdated: 'Update erfolgreich abgeschlossen.',
  failed2Distribute:
    'Die Verteilung der Teilnehmer: {{name}} war jedoch erfolglos.',
  search: 'Suche',
  deleteEventGridMsg: 'Möchten Sie wirklich die Bestenliste löschen???',
  deleteEventMsg: 'Möchten Sie dieses Turnier wirklich löschen?',
  deleteParticipantMsg: 'Möchten Sie diesen Teilnehmer wirklich löschen?',
  deleteAthleteMsg: 'Sind Sie sicher, dass Sie diesen Athleten löschen können?',
  deleteCategoryMsg: 'Möchten Sie diese Kategorie wirklich löschen?',
  deleteCategoryGroupMsg:
    'Sind Sie sicher, dass Sie diese Kategoriegruppe löschen möchten?',
  deleteUserMsg: 'Möchten Sie diesen Benutzer wirklich löschen?',
  deleteCertificateMsg: 'Möchten Sie dieses Zertifikat wirklich löschen?',
  deleteDocumenMsg: 'Möchten Sie dieses Dokument wirklich löschen?',
  deleteJudgeMsg: 'Sind Sie sicher, dass Sie diesen Richter entfernen möchten?',
  deleteMsg:
    'Sind Sie sicher, dass Sie die Kata-Disziplin für diese Kategorie entfernen wollen?',
  deleteRecord: '{{name}} löschen',
  deleteParticipantWithCategoriesMsg:
    'Um diesen Teilnehmer erfolgreich zu löschen, heben Sie zunächst die Zuweisung des Teilnehmers aus allen Kategorien auf, indem Sie das Kontrollkästchen deaktivieren.',
  deleteAthleteWithCategoriesMsg:
    'Um diesen Athleten erfolgreich zu entfernen, heben Sie zunächst seine Zuordnung zu allen Turnierkategorien auf, indem Sie das Kontrollkästchen deaktivieren.',
  deleteCategory: 'Kategorie löschen',
  deleteCategoryGroup: 'Kategoriegruppe löschen',
  deletePermanently: 'Dauerhaft löschen',
  confirm2deletePermanently:
    'Um mit der dauerhaften Löschung des Turniers fortzufahren, bestätigen Sie bitte den Vorgang durch Eingabe des 6-stelligen Codes <<{{name}}>>',
  letsDoIt: 'Lass es uns tun!',
  deleteUser: 'Benutzer löschen',
  useLatinChars: 'Verwenden Sie die Lateinische Zeichen ',
  useCyrillicChars: 'Verwenden Sie die Kyrillische Zeichen ',
  id: 'ID',
  ikoCard: 'I.K.O Card',
  kyuDan: 'Kyu/Dan',
  name: 'der Name',
  denomination: 'Name des Ereignisses',
  age: 'die Alter',
  country: 'das Land',
  gender: 'Geschlecht',
  units: 'Einheiten',
  weight: 'das Gewicht',
  weightFromTo: 'Gewicht von / bis',
  weightInfoMark:
    'Basierend auf dem angegebenen Wert werden zwei Spiegelkategorien erstellt. Eine Kategorie - für Gewicht weniger als der eingegebene Wert, die zweite - für Gewicht mehr als es.',
  category: 'die Kategorie',
  coach: 'die Trainer',
  coaches: 'die Trainer',
  countries: 'die Länder',
  countriesForm: 'die Länder',
  type: 'die Art',
  date: 'Datum',
  eventStartDate: 'Startdatum der Veranstaltung',
  startDay: 'Datum der Veranstaltung',
  eventStart: 'Veranstaltungsbeginn',
  sportEvent: 'Sportveranstaltung',
  training: 'Ausbildung',
  nextKyuDan: 'Nächster Kyu/Dan',
  exams: 'Prüfungen',
  time: 'die Zeit',
  preliminaryRounds: 'Vorrunden',
  semifinalAndFinals: 'Halbfinale und Finale',
  mainAndExtraTime: 'Haupt- und Verlängerung',
  city: 'die Stadt',
  address: 'die Anschrift',
  secretary: 'Hauptsekretär',
  mainJudge: 'Hauptrichter',
  allTournaments: 'Alle Turniere',
  allParticipants: 'Alle Teilnehmer',
  from: 'von',
  to: 'bis',
  birthday: 'das Geburtsdatum',
  newerDate: 'Bitte geben Sie ein neueres Datum ein',
  club: 'der Club',
  region: 'die Region',
  role: 'die Rolle',
  upload: 'Hochladen',
  description: 'Beschreibung',
  eventName: 'der Turniersname',
  eventType: 'Turniertyp',
  submit: 'Senden',
  more: 'Mehr',
  phone: 'das Telefon',
  bestResults: 'Beste Ergebnisse in den letzten 3 Jahren',
  website: 'die Website',
  height: 'die Höhe',
  history: 'Schiedsrichterhistorie im letzten Jahr',
  selectCoachFirst: 'Zuerst wählen Sie den Trainer',
  noTournaments: 'Keine Turniere',
  registrationDate: 'Anmeldeschluss',
  registrationDateParticipants: 'Anmeldeschluss für Teilnehmer',
  local: 'Lokales Turnier',
  international: 'Internationales Turnier',
  trainingSchool: 'Trainingslager',
  cancel: 'Abbrechen',
  error: 'Ein Fehler ist aufgetreten',
  notFound: 'Nicht gefunden',
  appNotFound: 'App nicht gefunden',
  deletedApp: 'App wird gelöscht',
  noRights: 'Unzureichende Rechte',
  fillInputs: 'Füllen Sie alle Eingaben',
  incompleteData: 'Unvollständige Daten',
  deletedSuccessfully: 'Erfolgreich gelöscht',
  less: 'Weniger',
  close: 'Schließen',
  required: 'Erforderlich',
  updateFailed: 'Das update konnte nicht abgeschlossen werden',
  cannotSaveChanges:
    'Ihre änderungen können nicht gespeichert werden. Bitte Kontaktieren Sie den support für weitere Unterstützung.',
  invalidEmail: 'Ungültige Email',
  requiredPasswordLength:
    'Ihr Passwort muss mindestens 4 und nicht mehr als 16 Zeichen lang sein',
  passwordsDoNotMatch: 'Die Passwörter stimmen nicht überein',
  emailAlreadyUsed: 'E-Mail wird bereits verwendet',
  serverError: 'Der Server antwortet nicht. Überprüfen Sie Ihre Verbindung.',
  wrongCurrentPasswordMsg:
    'Falsches Passwort. Bitte versuchen Sie es noch einmal',
  updatedPasswordMsg: 'Passwort aktualisiert',
  registerNewUserMsg:
    'Eine Bestätigung wurde an Ihre E-Mail. Klicken Sie auf den link, um zu bestätigen Ihre E-Mail',
  editParticipantInformationMsg:
    'um Informationen zu einem Teilnehmer zu bearbeiten, klicken Sie in der Tabelle auf ihn',
  typeEmails: 'Geben Sie eine E-Mail-Adresse ein',
  invalidEmailMsg:
    'Sie haben ungültige E-Mail. Bearbeiten Sie die E-Mail, um die Einladung zu senden.',
  signUp: 'Ein Konto erstellen',
  forgotPassword: 'Haben Sie das Passwort vergessen?',
  changePassword: 'das Passwort ändern',
  chooseNewPassword: 'Geben Sie ein neues Kennwort ein',
  submitChange: 'ändern',
  alreadyHaveAnAccount: 'Habe bereits ein Konto',
  confirmPassword: 'Bestätigen Sie Ihr Passwort ',
  send: 'Senden',
  resetPasswordMsg:
    'Bitte geben Sie die E-Mail-Adresse ein, die Sie für die Registrierung verwendet haben. Wir senden Ihnen dann eine E-Mail, um Ihr Passwort zurückzusetzen.',
  emailVerficationFailedMsg:
    'Die Anzahl der Versuche zur Passwortwiederherstellung wurde überschritten. Sie müssen es innerhalb von 24 Stunden erneut versuchen oder sich an die Verwaltung wenden',
  emailVerficationNoUserMsg:
    'Email-überprüfung nicht gesendet, bitte versuchen Sie es später noch einmal.',
  requestSentToYourEmail: 'Anfrage habe an Ihre E-Mail gesendet',
  goBack: 'zurück',
  notSentTo:
    'Leider kann Ihre Einladung nicht an einen oder mehrere Empfänger gesendet werden',
  invalidPassword: 'Ungültiges Passwort',
  wrongEmail:
    'Die von Ihnen eingegebene E-Mail ist nicht mit einem Konto verbunden.',
  noSymbols: 'Keine Symbole',
  lettersRequired: 'Buchstaben erforderlich',
  digitsOnly: 'Es sind nur Ziffern zulässig',
  updateRecord: 'Aktualisieren {{name}}',
  registered: 'Registrierte',
  registrationN: 'Anmeldung',
  passedRegistration: 'Eingetragen',
  notRegistered: 'Nicht registriert',
  noTournamentSpecified:
    'Das Turnier konnte nicht gefunden werden oder es fehlen Zugriffsberechtigungen',
  admisDocs: 'Dokumentiert',
  admisWeighs: 'Wiegen',
  admisEquipment: 'Ausrüstung',
  admisMedic: 'Arzt',
  admins: 'die Administratoren',
  masters: 'die Meister',
  registerers: 'die Register',
  coachesTotal: 'Insgesamt Trainer',
  pageDoesNotExists: 'Diese Seite existiert nicht.',
  insertPinFirst: 'zuerst PIN einfügen',
  pin: 'Eingeben PIN Sie',
  selectParticipants: 'Auswählen Sie die Teilnehmer ',
  getTournament: 'Turnier erhalten',
  attach: 'Zur Turnier hinzufügen',
  events: 'die Turnier',
  event: 'die Turnier',
  eventStatistics: 'das Turniersstatistik',
  select: 'Wählen',
  approvedJudges: 'Von Richtern genehmigt',
  participantRequestSent: 'Ihre Teilnehmer Anfrage wurde gesendet',
  participationApproved: 'Teilnahme genehmigt',
  participationDisapproved: 'Teilnahme genehmigt nicht',
  approveRegistration: 'Teilnahme Zulassen',
  participantsTotal: 'Teilnehmer',
  requests: 'Anwendungen',
  accesptedRequests: 'Bewerbungen angenommen',
  saveList: 'Bericht einholen',
  approve: 'Genehmigen',
  approbation: 'Genehmigen',
  male: 'Männlich',
  female: 'Weiblich',
  unisex: 'Unisex',
  additionToCategoryFailed:
    'Es gab mindestens eine erfolglose Ergänzung aufgrund einer Überschreitung der Anmeldefrist oder der Teilnehmerzahl.',
  failToRemoveParticipant:
    'Ich konnte den Athleten nicht löschen. Er ist mit einem Ereignis verknüpft.',
  participantsRemainingMsg:
    'Erfolgreich aktualisiert. Kategorien mit Teilnehmern bleiben markiert.',
  noAttachedTatamisMsg:
    'Um einem Turnier Kategorien zuzuweisen, benötigen Sie möglicherweise mindestens eine Tatami',
  assignCategoriesToTournament: 'Kategorien zum Turnier hinzufügen',
  kids: 'Kinder',
  juniors: 'Junioren',
  cadets: 'Kadettinnen',
  adults: 'Erwachsene',
  veterans: 'Veteranen',
  eventCode: 'Ereignis-PIN-Code',
  organizer: 'Organisatoren',
  tournOrganizer: 'Veranstalter',
  tournamentReference: 'Turnierreferenz.',
  reportWinners: 'Liste der Gewinner des Kategorieturniers.',
  reportCoachClub:
    'Allgemeine Liste der Turnierteilnehmer nach Trainer/Verein.',
  reportListOfCategoties:
    'Zusammenfassende Liste der Turnierteilnehmer nach Kategorien.',
  reportParticipants: 'Generierung von Registrierungsnummern der Teilnehmer.',
  reportRegistration: 'Liste der registrierten Turnierteilnehmer.',
  reportRunners: 'Turnierregistrierungsbelege werden generiert.',
  reportRatting:
    'Kategorie - Kumite, Bewertung der Athleten basierend auf den Ergebnissen der Kämpfe.',
  reportCategories:
    'Allgemeine Liste der Turnierkategorien mit Einstellungen und Teilnehmerzahl',
  reportCategoryType: 'Turniertabelle in der Kategorie {{name}}.',
  reportApplicants:
    'Liste der Bewerber für die Teilnahme an der Veranstaltung.',
  reportCategoryDistribution: 'Kategorieverteilung nach Tatami.',
  addNew: 'Neu hinzufügen',
  addAthlete: 'Fügen Sie einen Athleten hinzu',
  addCategory: 'Kategorie hinzufügen',
  updateCategory: 'Kategorie aktualisieren',
  all: 'Alle',
  allPersons: 'Alle',
  edit: 'Bearbeiten',
  winners: 'Gewinner',
  wainting: 'Warten',
  winnerOf: 'Gewinner von {{name}}',
  loserOf: 'Verlierer von {{name}}',
  listOfParticipants: 'Teilnehmerliste',
  reports: 'Berichte',
  tournamentResults:
    'Mannschaftsergebnis des Turniers. Gold, Silber und Bronze.',
  addCoach: 'Registrar Hinzufügen',
  schemeJudges: 'Anordnung der Richter',
  closeScheme: 'Diagramm schließen',
  hideRefereePlacementScheme: 'Schiedsrichter-Platzierungstabelle ausblenden',
  judgeNoTatami:
    'Um auf diese Funktion zugreifen zu können, müssen Sie dem Turnier zunächst Schiedsrichter und mindestens eine Tatami hinzufügen',
  judges: 'Richter',
  approveJudges: 'Richter genehmigen',
  addJudge: 'Richter Hinzufügen',
  updateJudge: 'Richter Aktualisieren',
  experience: 'Erfahrung',
  positions: 'Positionen',
  removePosition: 'Position entfernen',
  tatami: 'Tatami',
  tatami: 'Tatami',
  broadcastLink: 'Broadcast-Link',
  videoLinks: 'Videolinks',
  blocksCount: 'Anzahl der Blöcke',
  block: 'Block {{count}}',
  tatamiBlock: 'Block',
  addDeleteBlock: 'Block löschen / hinzufügen',
  blockName: 'Blockname',
  tatamiCategories: 'Tatami/die Kategorien',
  currentPassword: 'Aktuelles Passwort',
  newPassword: 'Neues Passwort',
  invalidCharacters: 'Ungültige Zeichen',
  cannotHaveMoreThan32Chars: 'Darf nicht mehr als 32 Zeichen haben ',
  actionFailed: 'Die Aktion konnte nicht abgeschlossen werden',
  invalidValue: 'Ungültiger Wert',
  invalidDate: 'Ungültiges Datum',
  invalidTime: 'Ungültige Zeit',
  futureDate:
    'Das Datum darf nicht vor dem aktuellen Datum und dem Anmeldedatum liegen.',
  registrationDateError:
    'Das Datum muss mit dem Startdatum der Veranstaltung übereinstimmen oder vor diesem liegen',
  minimumAllowableWeight: 'Gewicht darf nicht kleiner als 10 sein',
  noCategories: 'Keine Kategorien',
  approvedAthletes: 'Anerkannte Sportler',
  awaitingApproval: 'Warten auf Genehmigung',
  approvedParticipants: 'Zugelassene Teilnehmer',
  unapprovedParticipants: 'Nicht genehmigte Teilnehmer',
  participantKicked: 'Teilnehmer getreten',
  newParticipant: 'Neuer Teilnehmer',
  save: 'Speichern',
  Cancel: 'Absagen',
  closeDiscard: 'Schließen und verwerfen',
  cancelUnsavedChangessMsg:
    'Durch das Schließen dieses Fensters werden alle nicht gespeicherten Änderungen verworfen.',
  discardChanges: 'Änderungen verwerfen?',
  noOptionsText: 'Keine Optionen',
  openText: 'öffnen',
  clearText: 'löschen',
  noResults: 'Keine Ergebnisse',
  noRecords: 'Derzeit sind keine Aufzeichnungen vorhanden.',
  recorderNoEvents: 'Sie haben derzeit keinen Zugriff auf Veranstaltungen',
  createRecordsMsg:
    'Sie haben die Möglichkeit, bevorstehende Veranstaltungen zu planen!',
  butCreateRecordsMsg:
    'Sie haben jedoch die Möglichkeit, bevorstehende Veranstaltungen zu planen!',
  profile: 'Mein Profil',
  userDetails: 'Benutzerdetails',
  invite: 'Einladen',
  invitationsSent: 'Die Einladungen wurden verschickt',
  defaultInvitationTxt: 'Der Standardtext der Einladung',
  invitationTxtRu: 'Einladungstext auf Russisch',
  invitationTxtRo: 'IEinladungstext auf Rumänisch',
  link2QuickRegistration: 'Link zur E-Anmeldung.',
  teamRepresentative: 'Teamvertreter',
  teamRepresentativeNoSelection: 'Bitte wählen Sie einen Teamvertreter aus',
  sendTo: 'nach ',
  previewTooltip: 'Vorschau',
  preview: 'Email-Vorschau',
  tooManyRequests: 'Einladungsgrenze erreicht',
  restore: 'Wiederherstellen',
  estimatedEventTime: 'Geschätzte Veranstaltungszeit',
  totalBlocks: 'Gesamtblöcke',
  totalTatami: 'Total Tatami',
  totalCategories: 'Gesamtkategorien',
  assignParticipant: 'Ordnen Sie die Teilnehmer der Kategorie zu',
  assignParticipantToTraining: 'Weisen Sie Teilnehmer einem Training zu',
  assignYourself: 'weisen Sie sich zu',
  enableBronzeFight: 'Kampf um den 3. Platz',
  numberOfPlaces: 'Anzahl der Preise',
  numberOfPlacesHelperTxt:
    'Absolute Europameisterschaftsregeln (8 Preisplätze)',
  removeParticipantCateory: 'Teilnehmerkategorie entfernen',
  removeParticipantCateoryMsg:
    'Möchten Sie den Teilnehmer wirklich aus dieser Kategorie entfernen {{name}}?',
  removeParticipantTournament: 'Entfernen Sie einen Teilnehmer aus dem Turnier',
  removeParticipantTournamenMsg:
    'Sind Sie sicher, dass Sie ein Mitglied aus allen Kategorien entfernen möchten?',
  editParticipantCategory: 'Teilnehmerkategorie bearbeiten',
  tournamentIsOverMsg:
    'Teilnehmer können nicht angehängt werden, das Turnier ist vorbei.',
  assignParticipantError: 'Fehler beim zuordnen der Teilnehmer',
  threeParticipantsTeamKata: 'Sie haben bereits 6 Mitglieder in dieser Gruppe',
  renumber: 'Neu nummerieren',
  generateBracket: 'Rangliste zeigen',
  bracketInformation: 'Bestenliste',
  drawStatistics: 'Statistik der Auslosung',
  firstPlaces: 'Erste Plätze',
  secondPlaces: 'Zweite Plätze',
  thirdPlaces: 'Dritte Plätze',
  printedDiplomas: 'Gedruckte Diplome',
  unprintedDiplomas: 'Ungeöffnete Diplome',
  totalCategories: 'Gesamtkategorien',
  prizeCategories: 'Preiskategorien',
  totalParticipants: 'Gesamtzahl der Teilnehmer',
  noBracketMsg: 'Es gibt keine Informationen über die Turnier-Klammer',
  number: 'die Zahl',
  tournSettings: 'Ereigniseinstellungen',
  categorySettings: 'Kategorieeinstellungen',
  generalSettings: 'Allgemeine Event-Einstellungen',
  date2CalculateNumberOfYears:
    'Datum der Berechnung der Anzahl der vollen Jahre',
  permissionToDownload: 'Wer sollte die Erlaubnis zum Download haben?',
  permissions: 'die Erlaubnis',
  registrar: 'Vertreter der Mannschaft',
  operator: 'der Betreiber',
  user: 'der Benutzer',
  status: 'der Status',
  parameter: 'der Parameter',
  value: 'der Wert',
  coachesOnly: 'Nur Trainer',
  masterOnly: 'Nur ich',
  noPartNumber: 'Teilnehmernummern deaktivieren',
  participantNumbers: 'Teilnehmerzahlen',
  noCountry: 'Land bei der Registrierung ausblenden',
  kataFinal: 'Finale der KATA-Teilnehmer',
  publishTourn: 'Veröffentlichen Sie eine Veranstaltung',
  privatizeTourn: 'Turnier privatisieren',
  eventClosed: 'Die Veranstaltung ist geschlossen',
  registrationOpened: 'Die Anmeldung ist geöffnet',
  registrationClosed: 'Die Registrierung ist geschlossen',
  deleteEventGrid: 'Löschen Sie die Rangliste',
  closeTournament: 'Willst du das Turnier wirklich beenden?',
  closeTournamentMsg:
    'Sobald das Turnier geschlossen ist, können Sie keine Änderungen mehr daran vornehmen.',
  userExists: 'Ein Konto mit dieser Email-Adresse ist bereits vorhanden',
  removeParticipantTourn: 'Entfernen Sie einen Teilnehmer aus einem Turnier',
  doesNotExist: 'existiert nicht',
  team: 'das Team',
  teams: 'die Teams',
  judgeTeams: 'die Teams',
  judgeTeam: 'Team',
  deleteJudgeTeam: 'Team löschen',
  removeParticipantTournMsg:
    'Möchten Sie den Teilnehmer wirklich aus dem Turnier entfernen?',
  reassign: 'Neu zuweisen',
  reassignParticipantBeforeCategoryUnassingment:
    'Stellen Sie vor dem Ausscheiden einer Kategorie aus dem Turnier sicher, dass alle Teilnehmer dieser Kategorie einer anderen Kategorie zugewiesen werden.',
  assign: 'Zuweisen',
  resetAll: 'zurücksetzen',
  generate: 'Automatische Verteilung',
  pendingApproval: 'Ausstehende Genehmigung',
  rank: 'der Ort',
  selectCertificate: 'Zertifikat auswählen',
  selectCoaches:
    'Wählen Sie die Trainer aus, die Sie per E-Mail senden möchten',
  valueExists: 'Der Wert existiert bereits',
  emptyState: 'Es ist leer hier drin!',
  participantList: 'Liste der Teilnehmer',
  categoriesList: 'Kategorienliste',
  filter: 'der Filter',
  export: 'der Export',
  participantRegistration: 'Registrierung der Teilnehmer',
  runners: 'die Läufer',
  rating: 'die Bewertung',
  categoriesCount: 'Anzahl der Kategorien',
  participantsCount: 'anzahl der Teilnehmer',

  //Quick registration to tourn
  coachRecorderUseQuickRegLinkInfo:
    'Um sich für die Veranstaltung anzumelden, müssen Sie vom Veranstalter einen Einladungslink oder PIN-Code erhalten.',
  registerToTourn: 'Registrierung der Veranstaltung',
  sendRequest: 'Eine Anfrage senden',
  downloads: 'die Downloads',
  download: ' die Download',
  otherVersions: 'Andere Versionen',
  dojoInfo: 'Dojo-information"',
  tournamentInfo: 'Turnierinformationen',
  myFN: 'Mein Vorname',
  myLN: 'Mein Nachname',
  myEmail: 'Meine Email',
  email: 'Email',
  confirmEmail: 'E-Mail Adresse bestätigen',
  myCoach: 'Mein Trainer',
  myCountry: 'Mein Land',
  myCity: 'Meine Stadt',
  myRegion: 'Meine region',
  tournDoc: 'Turnier-Dokumente',
  doc: 'die Dokumente',
  branchChief: 'Branch chief',
  nameBranchChief: 'Vollständiger Name Zweigstellenleiter',
  summary: 'Zusammenfassung',
  failedToSendRequest: 'Die Anfrage konnte nicht gesendet werden',
  failedToSendRequestMsg:
    'Um sich zu bewerben, müssen Sie mindestens einen Teilnehmer in mindestens einer Kategorie haben',
  copyTxtMsg:
    'Wenn Sie diese Anwendung bearbeiten müssen, verwenden Sie den folgenden Link:',
  openRegistration:
    'Die Anmeldung ist geöffnet. Der letzte Anmeldetag ist der {{date}}',
  closedRegistration:
    'Die Registrierung ist geschlossen. Der letzte Anmeldetag war der {{date}}',
  openJudgeRegistration:
    'Die Registrierung der Richter ist geöffnet. Letzter Tag der Registrierung: {{date}}',
  closedJudgeRegistration:
    'Die Richterregistrierung ist geschlossen. Letzter Tag der Registrierung war {{date}}',
  openRegistrationBtn: 'Anmeldung zum offenen Turnier',
  closeRegistrationBtn: 'Turnieranmeldung schließen',
  disableRegistration: 'Registrierung abbrechen',
  register: 'Registrieren',

  //tour guide
  endTour: 'Ende der Tour',
  closeTour: 'Schlu``ssen der Tour',
  registerDojo:
    'Registrieren Sie Ihr Dojo, indem Sie das aktuelle Formular ausfüllen und absenden',
  addPartStep: 'Füge dein Team zu den Turnierkategorien hinzu',
  useImgLater: 'Gibt Ihnen die Möglichkeit, {{name}} später zu verwenden',
  registerTeamStep: 'Melde dein Team für das Turnier an',

  useLogo: 'Gibt Ihnen die Möglichkeit, das {{name}} später zu verwenden',
  logo: 'Logo',
  stamp: 'Briefmarke',
  signature: 'Unterschrift',
  bronzeSkirmish: 'Bronze-Gefecht',
  round: 'Runde',
  final: 'Finale',
  score: 'Ergebnis',
  sort: 'Sortieren',
  descending: 'Absteigender',
  ascending: 'Aufsteigend',
  systemCategories: 'Systemkategorien',
  customCategories: 'Meine Kategorien',
  payments: 'Zahlungen',
  payment: 'Zahlung',
  monthlyPayment: 'Monatliche',
  annualPayment: 'Jährliche',
  circleRound: 'Führen Sie ein Round Robin durch',
  tameshiwari: 'Tameshiwari',
  kataByFlags: 'Kata von Flags',
  fist: 'Faust (Seiken)',
  secondTry: 'zweiter Versuch',
  footRib: 'Fußrippe (Sokuto)',
  elbow: 'Ellbogen (Enpi)',
  edgeOfPalm: 'Rand der Handfläche (Shuto)',
  outcome: 'Ergebnis',
  lastNameFirst: 'Nachname dann Vorname',
  indicateKataNames: 'Legen Sie die Namen der Kata-Disziplinen fest',
  kataDiscipline: 'Kata-Disziplinen',
  red: 'Rot',
  blueSky: 'Blauer Himmel',
  yellow: 'Gelb',
  cyan: 'Cyan',
  violet: 'Violett',
  plum: 'Pflaume',
  green: 'Grün',
  white: 'Weiss',
  blue: 'Blau',
  transparent: 'Deaktivieren',
  color: 'Farbe',
  colorsHelperTxt: 'Wählen Sie die Farbe, die Ihnen gefällt',
  scoreboardColor: 'Farbe der Anzeigetafel',
  roundKataFlags: `Runden {{count}}`,
  kumite: 'Kumite',
  kata: 'Kata',
  teamKata: 'Team Kata',
  pointKumite: 'Point Kumite',
  autoSelectionByAgeSex:
    'Automatische Auswahl nach Alter und Geschlecht/Gewicht',
  noReport: 'Derzeit gibt es keinen solchen Bericht.',
  successRegistration: 'Sie sind registriert! Jetzt können Sie sich anmelden.',
  alternative: 'Alternativer',
  nameMinusCategories: 'Name minus Kategorie',
  namePlusCategories: 'Name plus Kategorie',
  assignCategories: 'Kategorien zuweisen',
  assignTatami: 'Tatami zuweisen',
  distributeCategories: 'Verteilen Sie Kategorien zwischen Tatami',
  distributeParticipantsByCategories:
    'Verteilen Sie die Teilnehmer auf die Kategorien',
  distributeParticipantsByCategoriesError:
    'Stellen Sie sicher, dass Sie einen Teilnehmer und eine Kategorie ausgewählt haben',
  transfer: 'Überweisen',
  copy: 'Kopieren',
  assigned: 'Ernannt',
  unassigned: 'Nicht zugeordnet',
  specifyHeight: 'Geben Sie bei der Anmeldung die Körpergröße an',
  specifyCoach2: 'Geben Sie einen zweiten Trainer an',
  male0To11: 'Jungen',
  female0To11: 'Mädchen',
  male11To15: 'Jungen',
  female11To15: 'Mädchen',
  male16To17: 'Junioren',
  female16To17: 'Junioren',
  male18plus: 'Männer',
  female18plus: 'Frauen',
  male35plus: 'Veteranen',
  female35plus: 'Frauen',
  nameRegistrar: 'Name des Registrars',
  infRegistrar: 'Informationen zum Standesamt',
  nameCategories: 'Namenskategorie',
  nameCategoriesForm: 'Namenskategorie',
  inanimateName: 'Name',
  groupAddUpdate: 'Gruppe',
  absoluteCategory: 'Absolute Kategorie',
  categoryGroup: 'Kategoriegruppe',
  owner: 'Erstellt von',
  nameEvent: 'Name des Ereignisses',
  requestCall: 'Fordern Sie einen Anruf an',
  home: 'Heim',
  aboutUs: 'Über uns',
  products: 'Produkte',
  contacts: 'Kontakte',
  myAccount: 'Mein Konto',
  createAccount: 'Ein Konto erstellen',
  softwareForProfessional: 'Software für Profis',
  managementSportingEvents: 'Management von Sportveranstaltungen.',
  fast: 'SCHNELL',
  comfortable: 'KOMFORTABEL',
  safe: 'SICHER',
  durationDays: 'Anzahl Tage',
  contactUs: 'Verbinde dich mit uns',
  showAll: 'Alle',
  showNotAccepted: 'Nicht bestätigt',
  provideThisAction:
    'Sind Sie sicher, dass Sie diese Aktion ausführen möchten?',
  yes: 'Ja',
  no: 'Nein',
  participantUpColor: 'Mitgliedsfarbe oben (links)',
  participantDownColor: 'Mitgliedsfarbe unten (rechts)',
  leaderboardSettings: 'Einstellungen der Informationstafel',
  district: 'Bereich',
  startDateAndTime: 'Datum und Uhrzeit des Beginns der Arbeit der Kommission',
  venueAndDatesOfTheEvent: 'Ort und Termine der Veranstaltung',
  placeAndDateOfTheCommission: 'Ausschuss für die Zulassung von Teilnehmern',
  applicationsForRegistrationAcceptedUntil:
    'Anträge auf Registrierung von Teilnehmern werden bis angenommen',
  applicationsForRefereeingAreAcceptedUntil:
    'Bewerbungen für das Schiedsrichterwesen werden angenommen bis',
  organisingCommittee: 'Organisationskomitee',
  chiefJudge: 'Hauptschiedsrichter des Wettbewerbs',
  chiefCompetitionSecretary: 'Oberster Wettbewerbssekretär',
  chairman: 'Vorsitzender des Organisationskomitees',
  organizingCommitteeAddress: 'Adresa comitetului de organizare',
  theCommission: 'Ausschuss für die Zulassung von Teilnehmern',
  commissionStartDate: 'Startdatum der Kommission',
  code: 'Code',
  administrator: 'Administrator',
  master: 'Organisator',
  queryType1: 'gibt sowohl Benutzer als auch deren Organisatoren zurück',
  queryType2: 'gibt nur Organisatoren zurück',
  queryType3: 'Gibt nur Organisatorbenutzer zurück',
  recorder: 'Registrator',
  regions: 'Regionen',
  cities: 'Städte',
  report: 'Bericht',
  achievements: 'Erfolge',
  registrationOfJudges: 'Anmeldung der Kampfrichter für das Turnier',
  declared: 'erklärt',
  accepted: 'akzeptiert',
  submitApplication: 'Anfrage annehmen',
  deleteApplication: 'Anfrage ablehnen',
  titleForReportsPortrait: 'Titel für Berichte (Porträt)',
  titleForReportsLandscape: 'Titel für Berichte (Querformat)',
  titleDescriptionPortrait:
    'Ermöglicht die Verwendung einer Kopfzeile für Berichte im Hochformat',
  titleDescriptionLandscape:
    'Ermöglicht Ihnen die Verwendung einer Kopfzeile für Berichte im Querformat',
  toggleReportHead: 'Berichtskopfzeilen deaktivieren',
  go2Participants: 'Wechseln Sie in den Teilnehmermodus',
  recommendSize: 'Empfohlene Pixelbildauflösung: {{size}} (B x H)',
  sportsCategory: 'Kategorie Sport',
  indicateSportsRank: 'Geben Sie bei der Registrierung den Sportrang an',
  reference: 'Referenz',
  qualJudgesRF: 'Qualifikation der Richter der Russischen Föderation',
  refereePositions: 'Schiedsrichterpositionen',
  categoryTypes: 'Kategorietypen',
  typeOfCategories: 'Kategorietyp',
  shortName: 'Abkürzung',
  boyGirl: 'Male, Female, Male, Female',
  girlBoy: 'Female, Male, Female, Male',
  boyBoy: 'Male, Male, Female, Female',
  girlGirl: 'Female, Female, Male, Male',
  groupBy: 'Gruppiere nach: ',
  portraitPageOrientation: 'Porträt',
  landscapePageOrientation: 'Landschaft',
  titleReport: 'Titel des Berichts',
  reportSettings: 'Berichtseinstellungen',
  pageSettings: 'Seiteneinstellungen',
  run: 'Laufen',
  turnOn: 'Anmachen: ',
  listOfJudges: 'Liste der Richter nach Tatami und Teams',
  compositionOfTheJury: 'Zusammensetzung der Jury',
  judgeSignature: 'Unterschrift des Generalsekretärs',
  secretarySignature: 'Unterschrift des Obersten Richters',
  stampOnReports: 'Berichte ausdrucken',
  signatureOnReports: 'Unterschreiben Sie Berichte',
  judgesForm: 'der Richter',
  useQRcode: 'Verwenden Sie bei der Registrierung den QR-Code',
  registration: 'Einstellungen zur Teilnehmerregistrierung',
  showIkoField: 'Geben Sie bei der Registrierung IKO an',
  showQdanField: 'Geben Sie bei der Registrierung Kyu/Dan an',
  showRegionField: 'Geben Sie bei der Registrierung die Region an',
  showClubField: 'Geben Sie bei der Anmeldung den Verein an',
  showCityField: 'Geben Sie bei der Registrierung die Stadt an',
  showMiddleName: 'Geben Sie bei der Registrierung Ihren zweiten Vornamen an',
  prohibitChangesAfterApproval:
    'Verbieten Sie Änderungen an einem Antrag nach der Genehmigung',
  preTournamentRegistration: 'Melden Sie sich vor dem Turnier an',
  importExcelHeader: 'Importieren einer Teilnehmerliste aus einer Excel-Datei',
  importSettingsFromOtherTournament: 'Importeinstellungen',
  importSettings: 'Ereigniseinstellungen importieren',
  AreYouSureYouWantToImportSettings:
    'Sind Sie sicher, dass Sie die Einstellungen von Turnier {{from}} in Turnier {{to}} importieren möchten?',
  info: 'Information',
  docIsBeingWritten:
    'Die Dokumentation für diese Seite wird gerade geschrieben...',
  useAlternativeCategoryName: 'Verwenden Sie einen alternativen Kategorienamen',
  eventInfo: 'Informationen zur Veranstaltung',
  registrarInfo: 'Teamvertreter',
  enterValue: 'Wert eingeben',
  selectValue: 'Wähle Wert',
  declaredWeight: 'Gewicht-1',
  sendingNotifications: 'Senden Sie Benachrichtigungen an den Telegram-Bot',
  switchToParticipantMode: 'Wechseln Sie in den Teilnehmermodus',
  switchToCategoryMode: 'Wechseln Sie in den Kategoriemodus',
  participantMode: 'Teilnehmermodus',
  categoryMode: 'Kategoriemodus',
  deleteTatamiMsg: 'Möchten Sie diese Tatami wirklich entfernen?',
  categoriesWillBeMoved:
    'Alle Kategorien werden auf die nächstgelegene verfügbare Tatami verschoben.',
  lastTatamiCannotBeRemoved: 'Die letzte Tatami kann nicht entfernt werden!',
  tournamentMustHaveOneTatami: 'Das Turnier muss mindestens eine Tatami haben.',
  showRetired: 'Im Ruhestand anzeigen',
  listOfWinnersByCategory: 'Liste der Gewinner nach Kategorie',
  awardProcedure: 'Vergabeverfahren',
  registrarsCount: 'Anzahl der Registrare',
  creationDate: 'Erstelldatum',
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  sunday: 'Sonntag',
  generateEmailToTournamentRegistrar:
    'Nach Abschluss dieser Aktion wird eine E-Mail mit einer Teilnehmerliste an den Veranstalter gesendet',
  showDeleted: 'Gelöscht anzeigen',
  assignParticipantTooltip: 'Teilnehmer in Kategorien einteilen',
  assignToJudging: 'Zum Richter ernennen',
  removeFromJudging: 'Vom Schiedsrichter abziehen',
  help: 'Hilfe',
  participantsStatisctics: 'Teilnehmerstatistik',
  tatamiStatistics: 'Tatami-Statistiken',
  statisticsByCategory: 'Statistiken nach Kategorie',
  statistics: 'Statistiken',
  selectTatamiNames: 'Tatami-Name',
  englishABCD: 'A, B, C, D',
  russianABVG: 'А, Б, В, Г',
  numbersWithRoundBackground: '❶, ❷, ❸, ❹',
  numbersInCircle: '①, ②, ③, ④',
  roman: 'Ⅰ, Ⅱ, Ⅲ, Ⅳ',
  removeAppMsg:
    'Sind Sie sicher, dass Sie diese Version der Anwendung deinstallieren möchten?',
  next: 'Zurück',
  back: 'Weiter',
  numberOfEntriesPerPage: 'Anzahl der Einträge pro Seite',
  eventAddress: 'Veranstaltungsort',
  addressCommission: 'Adresse der Kommission für die Zulassung von Teilnehmern',
  committeeEmail: 'E-Mail des Organisationskomitees',
  fightFor3rdPlace: 'Kämpfe um den 3. Platz',
  generalReportSettings: 'Allgemeine Berichtseinstellungen',
  reportSettings: 'Berichtseinstellungen',
  orientation: 'Seitenausrichtung',
  generalCategorySettings: 'Allgemeine Kategorieeinstellungen',
  license: 'Lizenz',
  LicenseTournamentExpert:
    'Lizenz zur Nutzung des Tournament Expert-Softwarepakets:',
  tourn: 'Turniere',
  noEvents: 'Es sind keine Veranstaltungen geplant.',
  createEvent: 'Erstellen Sie eine Veranstaltung',
  diploma: 'Diplom',
  invitationLink: 'Einladungs-Link',
  pincode: 'Pin-Code für die Veranstaltung',
  closeEvent: 'Veranstaltung schließen',
  importParticipantsFromExcel:
    'Importieren von Teilnehmern aus einer Excel-Datei',
  assignToPosition: 'Zur Ernennung auf die Stelle',
  importParticipantsFromExcel:
    'Importieren von Teilnehmern aus einer Excel-Datei',
  dateAndTimeOfTheEvent: 'Datum und Uhrzeit des Beginns',
  categoryOpponents: 'Kategorie-Rivalen',
  addTeamRepresentative: 'Teambeauftragten hinzufügen',
  applyFilter: 'Filter anwenden',
  ListOfAppliedTeamsforEvent:
    'Liste der Mannschaftsvertreter, die sich für die Veranstaltung angemeldet haben.',
  deleteAttachment: 'Dokument löschen',
  teamRepresentativesOnly: 'Nur Team-Vertreter',
  teamRepresentatives: 'Vertreter der Teams',
  editAttachment: 'Dokument bearbeiten',
  addOrganisation: 'Organisation hinzufügen',
  RegisterOrganisationInTheAdmissionList:
    'Registrierung der Organisation in der Zulassungsliste',
  registrationList: 'Registrierung der Liste',
  editRep: 'Aktualisieren Sie den Teamvertreter',
  title: 'Titel',
  rate: 'Bewertung',
  hidePastTournaments: 'Vergangene Ereignisse ausblenden',
  showPastTournaments: 'Vergangene Veranstaltungen anzeigen',
  userWrite: 'Schreiben Sie hier',
  sendchatMessage: 'Senden Sie uns eine Nachricht',
  weightCategories: 'Gewichtsklassen',
  regionalEvent: 'Regionale Veranstaltung',
  internationalEvent: 'Internationale Veranstaltung',
  historyTime: 'Nachrichten werden 48 Stunden lang gespeichert',
  replyTo: 'Antwort an: ',
  constantName: 'Beraterin',
  messagenoFound: 'Nachricht nicht gefunden',
  setPassword: 'Passwort festlegen',
  fightsNumbering: 'Nummerierung der Kämpfe',
  loadingoldMessages: 'Laden...',
  loadpreviousMessages: 'Wird geladen...',
  viewHistory: 'Geschichte ansehen',
  representatives: 'Vertreter',
  emailsDoNotMatch: 'E-Mails sind anders',
  documentNumber: 'Dokumentennummer',
  gridorderCategory: 'Nach Alter sortieren',
  disableNumberingOfFights: 'Nummerierung der Kämpfe deaktivieren',
  athleteName: 'Name des Athleten',
  trainerName: 'Name des Trainers',
  empty: 'Leer',
  showDocNumField: 'Dokumentennummer bei der Anmeldung angeben'
};
