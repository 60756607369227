export default {
  exit: 'Exit',
  lang: 'Language',
  english: 'English',
  russian: 'Russian',
  romanian: 'Romanian',
  german: 'German',
  templates: 'Templates',
  template: 'Template',
  subject: 'Subject',
  here: 'here',
  apply: 'Apply',
  proceed: 'Proceed',
  picture: 'Picture',
  photo: 'Photo',
  accountCreated: 'Account created!',
  accountFailToCreate: "Couldn't activate the account!",
  invitationListTitle: 'List of admissions for registration',
  chooseFromList: 'Select from list',
  fail2AddUser:
    'Failed to save changes. A user named "{{name}}" already exists in the system.',
  navigateTo: 'Navigate to',
  min: 'min',
  sec: 'sec',
  kg: 'kg',
  cm: 'cm',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',
  hour: 'Hour',
  hours: 'Hours',
  days: 'Days',
  day: 'Day',
  tournamentDay: 'Tournament day',
  years: 'years',
  errorBoundary: 'Oh-no! Something went wrong',
  logout: 'Log out',
  firstname: 'First name',
  lastname: 'Last name',
  firstNameNational: 'First name latin',
  lastNameNational: 'Last name latin',
  yourFirstName: 'Your Firstname',
  yourLastName: 'Your Lastname',
  yourPatronymic: 'Your Patronymic',
  coachFirstName: 'Firstname Coach',
  coachLastName: 'Lastname Coach',
  patronymic: 'Patronymic',
  tournament: 'Tournament',
  logIn: 'Log In',
  activateAccount: 'Activate account',
  loginIssue: 'Could not enter into system?',
  username: 'Username',
  password: 'Password',
  passwordChangedSuccessfully: 'Password changed successfully!',
  emailSent: 'An email has been sent to you to reset your password',
  followInstructions: 'Follow the instructions in the letter.',
  categories: 'Categories',
  categoriesForm: 'Categories',
  deleteCategoryErrorsBcOfTourn: `This category cannot be deleted as it is used in the following tournaments: {{name}}`,
  deleteGroupCategoryErrorsBcOfCategories: `This group cannot be deleted as it has categories`,
  participantsNum: 'Num of participants',
  participants: 'Participants',
  participant: 'Participant',
  athletes: 'Athletes',
  athlete: 'Athlete',
  athleteForm: 'Athletes',
  myTeam: 'My Team',
  myOrganisation: 'My Organisation',
  cluborFederationName: 'Club or Organisation',
  yourCluborFederationName: 'Your Club or Organisation',
  users: 'Users',
  logs: 'Logs',
  demoUsers: 'Demo Users',
  expandList: 'Expand list',
  total: 'Total',
  sum: 'Total',
  upcoming: 'Upcoming',
  finished: 'Past',
  planUpcomingEvents: 'Plan of upcoming events',
  pastEvents: 'Past Events',
  eventStatus: 'Event status',
  active: 'Active',
  inactive: 'Inactive',
  archive: 'Archive',
  showRed: 'Show red',
  appointed: 'assigned',
  filled: 'filled',
  human: 'man',
  categoriesTotal: 'Total {{name}} categories',
  categoriesUsed: 'Categories  used',
  redCategories: 'Red categories',
  addRecord: 'Add {{name}}',
  boys: 'Boys',
  girls: 'Girls',
  men: 'Men',
  women: 'Women',
  femaleGender: 'female gender',
  maleGender: 'male gender',
  moreApps: 'Download Apps',
  forDesktop: 'for Desktop',
  forMobile: 'for Mobile',
  downloadTournamentManagerInfo:
    'Desktop app is designed in order to help You holding the tournament. To be ready to organize a competition, download it now!',
  downloadCasterInfo:
    'A special application that emulates the operation of a scoreboard under Windows OS. Good alternative to Raspberry Pi. Use this app if you do not have a Raspberry Pi mini computer.',
  downloadCastManager:
    'An application for setting up the scoreboard and managing the relationship between the tatami operator and the image on the screen. With this application, the setup is easier and more convenient!',
  downloadMobileInfo:
    'Downloading of our Mobile app is now available. Easier, more convenient and faster! Download the app to your phone to keep up to date with the latest events.',
  downloadDiploma:
    'This is a program for printing and creating award diplomas, certificates and diplomas. If you have a printer at the tournament, send diplomas for printing immediately from the program as soon as the final matches are completed. Use this application to reward the finalists at the tournament!',
  availableFor: 'Available for',
  application: 'Application',
  versionLTS: 'Recommended For Most Users',
  version: 'Version',
  current: 'Current',
  certificates: 'Certificates',
  certificate: 'Certificate',
  document: 'Document',
  uploadDoc: 'Upload Document',
  addAttachment: 'Add Document',
  attachments: 'Attachments',
  attachment: 'Attachment',
  documents: 'Documents',
  documentsCount: 'Total documents',
  diplomas: 'Diplomas',
  regulations: 'Regulations',
  attachmentType: 'Document Type',
  supportedDocument: 'Supported Formats',
  nameDocument: 'Title of the document',
  acceptRequest: 'Accept request',
  rejectRequest: 'Reject request',
  uploadDate: 'Upload date',
  additional: 'Additional',
  toView: 'View',
  viewDetails: 'View Details',
  diplomaName: 'James Smith',
  diplomaCategory: 'Cadets 14-15 years -75 kg',
  diplomaConstructor: 'Diploma constructor',
  saveDiploma: 'Save diploma',
  noTemplateForDiploma: "There isn't a template established for the diploma.",
  judgeWithSuchPositionExists:
    'Judge "{{name1}}" has already been appointed to the Position "{{position}}". Do you want to replace it with Judge "{{name2}}"?',
  judicialQualifications: 'Documents confirming judicial qualifications',
  failed2Load: 'Failed to load file. No rights or corrupted file.',
  import: 'Import',
  noAttachmentsMsg: 'It looks like there are no attachments to download',
  noCertificates: "You don't have any certificates at this time.",
  certificateNameLimitExceed:
    'The title of the document cannot exceed 128 characters.',
  informativeUploadImageMsg:
    'To successfully import attachments please use .png, .jpg, .jpeg.',
  dragMsg: 'or drag and drop the document',
  rejectedFileError:
    'This attachment is not in the required format. Please upload an attachment with the correct format.',
  browse: 'Browse',
  isDragRejectMsg:
    'This file cannot be attached, because its format is not supported',
  certificateNameLabel: 'Certificate Name',
  fileTooLargeMsg:
    'This attachment exceeds 20MB. Please upload a smaller attachment.',
  imgTooBig: 'Attachment size exceeds the permissible limit, 3840x3840',
  attachment2Large: 'The attachment size is too large to be uploaded.',
  maxChars: 'Max {{max}} characters',
  minChars: 'Min {{num}} characters',
  dropFile: 'Drop file to attach',
  remove: 'Remove',
  insufficientData: 'Insufficient data',
  successfullyAdded: 'Successfully Added',
  resolveAllInputs: 'Resolve all inputs',
  successfullyUpdated: 'Update completed successfully.',
  failed2Distribute:
    'However, the distribution of participants: {{name}} was unsuccessful.',
  search: 'Search',
  deleteEventGridMsg: 'Are you sure you want to delete the standings?',
  deleteEventMsg: 'Are you sure you want to delete this event?',
  deleteParticipantMsg: 'Are you sure you want to delete this participant?',
  deleteAthleteMsg: 'Are you sure you want to delete this athlete?',
  deleteCategoryMsg: 'Are you sure you want to delete this category?',
  deleteCategoryGroupMsg:
    'Are you sure you want to delete this category group?',
  deleteUserMsg: 'Are you sure you want to delete this user?',
  deleteCertificateMsg: 'Are you sure you want to delete this certificate?',
  deleteDocumenMsg: 'Are you sure you want to delete this document?',
  deleteJudgeMsg: 'Are you sure you want to delete this judge?',
  deleteMsg:
    'Are you sure you want to remove the kata discipline for this category?',
  deleteRecord: 'Delete {{name}}',
  deleteParticipantWithCategoriesMsg:
    'To successfully delete this participant, first unassign the participant from all categories by unchecking the checkbox.',
  deleteAthleteWithCategoriesMsg:
    'To successfully remove this athlete, first cancel their assignment from all tournament category(s) by unchecking the box.',
  deleteCategory: 'Delete Category',
  deleteCategoryGroup: 'Delete Category Group',
  deletePermanently: 'Delete permanently',
  confirm2deletePermanently:
    'To proceed with the permanent deletion of the tournament, please confirm the action by entering the 6-digit code <<{{name}}>>',
  letsDoIt: "Let's do it!",
  deleteUser: 'Delete User',
  useLatinChars: 'Use latin characters',
  useCyrillicChars: 'Use cyrillic characters',
  id: 'ID',
  ikoCard: 'I.K.O Card',
  kyuDan: 'Kyu/Dan',
  name: 'Name',
  denomination: 'Name of the Event',
  age: 'Age',
  country: 'Country',
  gender: 'Gender',
  units: 'Units',
  weight: 'Weight',
  weightFromTo: 'Weight from / to',
  weightInfoMark:
    'Two mirror categories will be created based on the specified value. One category - for weight less than the entered value, the second - for weight more than it.',
  category: 'Category',
  coach: 'Coach',
  coaches: 'Coaches',
  coachForm: 'Coaches',
  totalRegistrars: 'Registrars',
  countries: 'Countries',
  countriesForm: 'Countries',
  type: 'Type',
  date: 'Date',
  eventStartDate: 'Event start date',
  startDay: 'Date of the event',
  eventStart: 'Event start',
  sportEvent: 'Sport event',
  training: 'Training',
  nextKyuDan: 'Next Kyu/Dan',
  exams: 'Exams',
  time: 'Time',
  preliminaryRounds: 'Preliminary rounds',
  semifinalAndFinals: 'Semi-finals and finals',
  mainAndExtraTime: 'Main and Extra time',
  city: 'City',
  address: 'Address',
  secretary: 'Chief Secretary',
  mainJudge: 'Main referee',
  allTournaments: 'All Tournaments',
  allParticipants: 'All Participants',
  from: 'From',
  to: 'To',
  birthday: 'Date of birth',
  newerDate: 'Please enter a newer date',
  club: 'Club',
  region: 'Region',
  role: 'Role',
  upload: 'Upload',
  description: 'Description',
  eventName: 'Event Name',
  eventType: 'Event Type',
  submit: 'Submit',
  more: 'More',
  phone: 'Phone',
  website: 'Website',
  height: 'Height',
  history: 'Refereeing history for the last 1 year',
  selectCoachFirst: 'Select Coach First',
  noTournaments: 'No Tournaments',
  bestResults: 'Best results in the last 3 years',
  registrationDate: 'Closing of registration',
  registrationDateParticipants: 'Closing date for registration of participants',
  local: 'Local Tournament',
  international: 'International Tournament',
  trainingSchool: 'Training Camp',
  cancel: 'Cancel',
  error: 'An error occured',
  notFound: 'Not Found',
  appNotFound: 'App not found',
  deletedApp: 'App is deleted',
  noRights: 'Insufficient rights',
  fillInputs: 'Fill all the inputs',

  incompleteData: 'Incomplete data',
  deletedSuccessfully: 'Successfully deleted',
  less: 'Less',
  close: 'Close',
  required: 'Required',
  updateFailed: 'The update failed to complete',
  cannotSaveChanges:
    'Your changes cannot be saved. Please contact support for further assistance.',
  invalidEmail: 'Invalid Email',
  requiredPasswordLength:
    'Your password must be at least 4 and no more than 16 characters',
  passwordsDoNotMatch: 'Passwords do not match',
  emailAlreadyUsed: 'Email is already used',
  serverError: "The server isn't responding. Check your connection.",
  wrongCurrentPasswordMsg: 'Incorrect password. Please try again.',
  updatedPasswordMsg: 'Password updated',
  registerNewUserMsg:
    'A confirmation letter has been sent to your email, click on the link to confirm your Email',
  editParticipantInformationMsg:
    'to edit information about a participant, click on him in the table',
  typeEmails: 'Type an email address',
  invalidEmailMsg:
    'You have invalid email. Edit the email to send the invitation.',
  signUp: 'Create an account',
  forgotPassword: 'Forgot password?',
  changePassword: 'Change Password',
  chooseNewPassword: 'Enter a new password',
  submitChange: 'Change',
  alreadyHaveAnAccount: 'Already have an account',
  confirmPassword: 'Confirm Password',
  send: 'Send',
  resetPasswordMsg:
    'Please enter the email address you used to register. We will then send you an email to reset your password.',
  emailVerficationFailedMsg:
    'The number of password recovery attempts has been exceeded. You need to try again in 24 hours or contact the administration',
  emailVerficationNoUserMsg:
    'Email verification not sent, please try again later.',
  requestSentToYourEmail: 'Request sent to your email',
  goBack: 'Go back',
  notSentTo:
    'Unfortunately, your invitation cannot be delivered to one or more recipients:',
  invalidPassword: 'Invalid password',
  wrongEmail: "The email you entered isn't connected to an account.",
  noSymbols: 'No symbols',
  lettersRequired: 'Letters required',
  digitsOnly: 'Only digits are allowed',
  updateRecord: 'Update {{name}}',
  registered: 'Registered',
  registrationN: 'Registration',
  passedRegistration: 'Registered',
  notRegistered: 'Not registered',
  noTournamentSpecified:
    'The tournament could not be located, or access permissions are lacking.',
  admisDocs: 'Documentation',
  admisWeighs: 'Weighs',
  admisEquipment: 'Equipment',
  admisMedic: 'Doctor',
  admins: 'Administrators',
  masters: 'Masters',
  registerers: 'Registerers',
  coachesTotal: 'Coaches Total',
  pageDoesNotExists: 'This page does not exist.',
  insertPinFirst: 'Insert PIN First',
  pin: 'Enter PIN code',
  selectParticipants: 'Select participants',
  getTournament: 'Get Event',
  attach: 'Add to Event',
  events: 'Events',
  event: 'Event',
  eventStatistics: 'Event statistics',
  select: 'Select',
  approvedJudges: 'Approved judges',
  participantRequestSent: 'Your participant request has been sent',
  participationApproved: 'Participation Approved',
  participationDisapproved: 'Participation Disapproved',
  approveRegistration: 'Claim Participation',
  participantsTotal: 'Participants',
  requests: 'Requests',
  accesptedRequests: 'accepted requests',
  saveList: 'Get report',
  approve: 'Approve',
  approbation: 'Approve',
  male: 'Male',
  female: 'Female',
  unisex: 'Unisex',
  additionToCategoryFailed:
    'There was at least one unsuccessful addition due to exceeded registration deadline or number of participants.',
  failToRemoveParticipant:
    'Unable to remove athlete. He is linked to an event.',
  participantsRemainingMsg:
    'Successfully updated. Categories containing participants will remain marked.',
  noAttachedTatamisMsg:
    'To assign categories to a tournament, you may need at least one tatami',
  assignCategoriesToTournament: 'Assigning categories to the tournament',
  kids: 'Kids',
  juniors: 'Juniors',
  cadets: 'Cadets',
  adults: 'Adults',
  veterans: 'Veterans',
  eventCode: 'Event PIN code',
  organizer: 'Organizers',
  tournOrganizer: 'Organizer',
  addNew: 'Add New',
  addAthlete: 'Add Athlete',
  addCategory: 'Add Category',
  updateCategory: 'Update Category',
  all: 'All',
  allPersons: 'All',
  edit: 'Edit',
  tournamentReference: 'Tournament reference.',
  reportWinners: 'Category Tournament Winners List.',
  winners: 'Winners',
  wainting: 'Waiting',
  winnerOf: 'Winner of {{name}}',
  loserOf: 'Loser of {{name}}',
  listOfParticipants: 'List of Participants',
  reportCoachClub: 'General List of Tournament Participants by Coach/Club.',
  reportListOfCategoties:
    'Summary List of Tournament Participants by Categories.',
  reportParticipants: 'Formation of Registration numbers of Participants.',
  reportRegistration:
    'List of Participants of the Tournament who passed Registration.',
  reportRunners: 'Formation of Receipts for Registration for the Tournament.',
  reportRatting:
    'Category - Kumite, Rating of athletes based on the results of fights.',
  reportCategories:
    'General List of Tournament Categories with settings and number of participants',
  reportCategoryType: 'Tournament table in the {{name}} category.',
  reportApplicants: 'List of Applicants for participation in the event.',
  reportCategoryDistribution: 'Category distribution by tatami.',
  reports: 'Reports',
  tournamentResults: 'Team result of the tournament. Gold, silver and bronze.',
  addCoach: 'Add Registrar',
  schemeJudges: 'Judge placement scheme',
  closeScheme: 'Close scheme',
  hideRefereePlacementScheme: 'Hide referee placement chart',
  judgeNoTatami:
    'To access this feature, you must first add referees and at least one tatami to the tournament.',
  judges: 'Referees',
  approveJudges: 'Approve Judges',
  addJudge: 'Add Referee',
  updateJudge: 'Update Referee',
  positions: 'Positions',
  removePosition: 'Remove position',
  experience: 'Experience',
  ikoQual: 'IKO Qualification',
  rfQual: 'Category',
  tatami: 'Tatami',
  tatamis: 'Tatamis',
  broadcastLink: 'Broadcast link',
  videoLinks: 'Video links',
  blocksCount: 'Number of blocks',
  block: 'Block {{count}}',
  tatamiBlock: 'Block',
  addDeleteBlock: 'Delete / Add Block',
  blockName: 'Block Name',
  tatamiCategories: 'Tatami/Categories',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  invalidCharacters: 'Invalid Characters',
  cannotHaveMoreThan32Chars: 'Cannot have more than 32 characters',
  actionFailed: 'Failed to complete the action',
  invalidValue: 'Invalid value',
  invalidDate: 'Invalid Date',
  invalidTime: 'Invalid Time',
  futureDate:
    'The date must be no earlier than the current date and the registration date',
  registrationDateError:
    'The date must match or be earlier than the start date of the event',
  minimumAllowableWeight: 'Weight cannot be less than 10',
  noCategories: 'No Categories',
  approvedAthletes: 'Approved athletes',
  awaitingApproval: 'Awaiting approval',
  approvedParticipants: 'Approved Participants',
  unapprovedParticipants: 'Unapproved Participants',
  participantKicked: 'Participant Kicked',
  newParticipant: 'New participant',
  save: 'Save',
  Cancel: 'Cancel',
  closeDiscard: 'Close & Discard',
  cancelUnsavedChangessMsg:
    'Closing this window will cancel any unsaved changes.',
  discardChanges: 'Discard changes?',
  noOptionsText: 'No options',
  openText: 'Open',
  clearText: 'Clear',
  noResults: 'No results',
  noRecords: 'There are currently no records.',
  recorderNoEvents: 'You currently do not have access to any events',
  createRecordsMsg:
    'You have the opportunity to make a plan for upcoming events!',
  butCreateRecordsMsg:
    'However, you have the opportunity to make a plan for upcoming events!',
  profile: 'My Profile',
  userDetails: 'User Details',
  invite: 'Invite',
  invitationsSent: 'The invitations have been sent',
  defaultInvitationTxt: 'The default text of the invitation',
  invitationTxtRu: 'Invitation text in russian',
  invitationTxtRo: 'Invitation text in romanian',
  link2QuickRegistration: 'Link to Quick Registration',
  teamRepresentative: 'Team representative',
  teamRepresentativeNoSelection: 'Please select a team representative.',
  sendTo: 'To',
  previewTooltip: 'Preview',
  preview: 'Email preview',
  tooManyRequests: 'Invite limit reached',
  restore: 'Restore',
  estimatedEventTime: 'Estimated event time',
  totalBlocks: 'Total blocks',
  totalTatami: 'Total tatamis',
  totalCategories: 'Total categories',
  prizeCategories: 'Prize categories',
  assignParticipant: 'Assign the Participants to Category',
  assignParticipantToTraining: 'Assign Participants to Training',
  assignYourself: 'assign yourself',
  enableBronzeFight: 'Fight for 3rd place',
  numberOfPlaces: 'Prize places',
  numberOfPlacesHelperTxt:
    'Absolute European Championship rules (8 prize places)',
  removeParticipantCateory: 'Remove participant category',
  removeParticipantCateoryMsg:
    'Are you sure you want to remove the participant from this category {{name}}?',
  removeParticipantTournament: 'Remove a participant from the tournament',
  removeParticipantTournamenMsg:
    'Are you sure you want to remove a member from all categories?',
  editParticipantCategory: 'Edit participant category',
  tournamentIsOverMsg:
    'Participants cannot be attached, the tournament is over.',
  assignParticipantError: 'Failed to assign the participant',
  threeParticipantsTeamKata: 'You already have 6 members in this group',
  renumber: 'Renumber',
  generateBracket: 'Show leaderboard',
  bracketInformation: 'Leaderboard',
  drawStatistics: 'Draw statistics',
  firstPlaces: 'First places',
  secondPlaces: 'Second places',
  thirdPlaces: 'Third places',
  printedDiplomas: 'Printed diplomas',
  unprintedDiplomas: 'Unprinted diplomas',
  totalCategories: 'Total categories',
  totalParticipants: 'Total participants',
  noBracketMsg: 'There is no information about the tournament bracket',
  number: 'Number',
  tournSettings: 'Event settings',
  categorySettings: 'Category settings',
  generalSettings: 'General event settings',
  date2CalculateNumberOfYears: 'Date to calculate the number of full years',
  permissionToDownload: 'Who should have permission to download?',
  permissions: 'Permissions',
  registrar: 'Team representative',
  operator: 'Operator',
  user: 'User',
  status: 'Status',
  parameter: 'Parameter',
  value: 'Value',
  coachesOnly: 'Coaches only',
  masterOnly: 'Me only',
  noPartNumber: 'Disable participant numbers',
  participantNumbers: 'Participant Numbers',
  noCountry: 'Hide Country during registration',
  kataFinal: "Participants' KATA final",
  publishTourn: 'Post an event',
  privatizeTourn: 'Privatize Tournament',
  eventClosed: 'Event is closed',
  registrationOpened: 'Registration is open',
  registrationClosed: 'Registration is closed',
  deleteEventGrid: 'Delete standings',
  closeTournament: 'Are you sure you want to close the event?',
  closeTournamentMsg:
    'Once the event is closed, you will not be able to make any changes to it.',
  userExists: 'An account with this email address already exists',
  doesNotExist: "doesn't exit",
  team: 'Team',
  teams: 'Teams',
  judgeTeams: 'Teams',
  judgeTeam: 'Team',
  deleteJudgeTeam: 'Delete Team',
  removeParticipantTourn: 'Remove tournament participant',
  removeParticipantTournMsg:
    'Are you sure you want to remove the participant from the tournament?',
  reassign: 'Reassign',
  reassignParticipantBeforeCategoryUnassingment:
    'Before eliminating a category from the tournament, ensure that all participants from the said category are reassigned to alternative categories.',
  assign: 'Assign',
  resetAll: 'Reset all',
  generate: 'Automatic distribution',
  pendingApproval: 'Pending approval',
  rank: 'Place',
  selectCertificate: 'Select Certificate',
  selectCoaches: 'Select the coaches you want to email',
  valueExists: 'The value already exists',
  emptyState: "It's empty in here!",
  participantList: 'Participants list',
  categoriesList: 'Categories list',
  filter: 'Filter',
  export: 'Export',
  participantRegistration: 'Participants Registration',
  runners: 'Runners',
  rating: 'Rating',
  categoriesCount: 'Number of categories',
  participantsCount: 'number of participants',

  //Quick registration to tourn
  coachRecorderUseQuickRegLinkInfo:
    'To register for the event, you need to receive an invitation link or PIN code from the organizer.',
  registerToTourn: 'Event Registration',
  sendRequest: 'Send Final',
  downloads: 'downloads',
  download: 'Download',
  otherVersions: 'Other Versions',
  dojoInfo: 'Dojo information',
  tournamentInfo: 'Tournament information',
  myFN: 'My first name',
  myLN: 'My last name',
  myEmail: 'My email',
  email: 'Email',
  confirmEmail: 'Confirm Email',
  myCoach: 'My coach',
  myCountry: 'My country',
  myCity: 'My city',
  myRegion: 'My region',
  tournDoc: 'event documents',
  doc: 'Documents',
  branchChief: 'Branch chief',
  nameBranchChief: 'My Branch Chief',
  summary: 'Summary',
  failedToSendRequest: 'The request could not be sent',
  failedToSendRequestMsg:
    'In order to submit the request, you need to have at least one participant in at least one category',
  copyTxtMsg: 'If you need to edit this application, use the link below:',
  openRegistration:
    'Registration is open. The last day of the registration is {{date}}',
  closedRegistration:
    'Registration is closed. The last day of registration was {{date}}',
  openJudgeRegistration:
    'Judge registration is open. Last day of registration {{date}}',
  closedJudgeRegistration:
    'Judge registration is closed. Last day of registration was {{date}}',
  openRegistrationBtn: 'Open Tournament Registration',
  closeRegistrationBtn: 'Close Tournament Registration',
  disableRegistration: 'Cancel registration',
  register: 'Register',

  //tour guide
  endTour: 'End tour',
  closeTour: 'Close tour',
  registerDojo:
    'Register your dojo by filling out and submitting the current form',
  addPartStep: 'Add your team to the tournament categories',
  registerTeamStep: 'Register your team to the tournament',

  useImgLater: 'Gives You ability to use {{name}} later on',
  logo: 'Logo',
  stamp: 'Stamp',
  signature: 'Signature',
  bronzeSkirmish: 'Bronze skirmish',
  round: 'Round',
  final: 'Final',
  score: 'Score',
  sort: 'Sort',
  descending: 'Descending',
  ascending: 'Ascending',
  systemCategories: 'System Categories',
  customCategories: 'My Categories',
  payments: 'Payments',
  payment: 'Payment',
  monthlyPayment: 'Monthly',
  annualPayment: 'Annual',
  circleRound: 'Conduct a circular round',
  tameshiwari: 'Tameshiwari',
  kataByFlags: 'Kata By Flags',
  fist: 'Fist (Seiken)',
  secondTry: 'second try',
  footRib: 'Foot rib (Sokuto)',
  elbow: 'Elbow (Enpi)',
  edgeOfPalm: 'Edge of the palm (Shuto)',
  outcome: 'Outcome',
  tournamentManager: 'Tournament Manager',
  lastNameFirst: 'Last name then first name',
  indicateKataNames: 'Set the names of kata-disciplines',
  kataDiscipline: 'kata-disciplines',
  red: 'Red',
  blueSky: 'Blue sky',
  yellow: 'Yellow',
  cyan: 'Cyan',
  violet: 'Violet',
  plum: 'Plum',
  green: 'Green',
  white: 'White',
  blue: 'Blue',
  transparent: 'Disable',
  color: 'Color',
  colorsHelperTxt: 'Choose the color you like',
  scoreboardColor: 'Scoreboard color',
  roundKataFlags: `Round {{count}}`,
  kumite: 'Kumite',
  kata: 'Kata',
  teamKata: 'Team Kata',
  pointKumite: 'Point Kumite',
  autoSelectionByAgeSex: 'Auto-selection by age and sex/weight',
  noReport: 'There is currently no such report.',
  successRegistration: 'You are registered! Now you can login.',
  alternative: 'Alternative',
  nameMinusCategories: 'Name for minus category',
  namePlusCategories: 'Name for plus category',
  assignCategories: 'Assign categories',
  assignTatami: 'Assign tatami',
  distributeCategories: 'Distribute categories between tatami',
  distributeParticipantsByCategories:
    'Distribute participants between categories',
  distributeParticipantsByCategoriesError:
    'Make sure you selected a participant and category',
  transfer: 'Transfer',
  copy: 'Copy',
  assigned: 'Assigned',
  unassigned: 'Unassigned',
  specifyHeight: 'Specify Height during registration',
  specifyCoach2: 'Specify a second coach',
  male0To11: 'Boys',
  female0To11: 'Girls',
  male11To15: 'Males',
  female11To15: 'Females',
  male16To17: 'Males',
  female16To17: 'Females',
  male18plus: 'Males',
  female18plus: 'Females',
  male35plus: 'Males',
  female35plus: 'Females',
  nameRegistrar: 'Name of the Registrar',
  infRegistrar: 'Information about the Registrar',
  nameCategories: 'Name Category',
  nameCategoriesForm: 'Name Category',
  inanimateName: 'Name',
  groupAddUpdate: 'Group',
  absoluteCategory: 'Absolute Category',
  categoryGroup: 'Category Group',
  owner: 'Created by',
  nameEvent: 'Name of the Event',
  requestCall: 'Request a call',
  home: 'Home',
  aboutUs: 'About Us',
  products: 'Products',
  contacts: 'Contacts',
  myAccount: 'My account',
  createAccount: 'Create an account',
  softwareForProfessional: 'Software for Professional',
  managementSportingEvents: 'Tournament Management',
  fast: 'FAST',
  comfortable: 'COMFORTABLE',
  safe: 'SAFE',
  durationDays: 'Amount of days',
  contactUs: 'Connect with us',
  showAll: 'All',
  showNotAccepted: 'Not approved',
  provideThisAction: 'Are you sure you want to perform this action?',
  yes: 'Yes',
  no: 'No',
  participantUpColor: 'Member color above (left)',
  participantDownColor: 'Member color below (right)',
  leaderboardSettings: 'Information board settings',
  district: 'District',
  startDateAndTime: 'Start date and time of the commission work',
  venueAndDatesOfTheEvent: 'Venue and dates of the event',
  placeAndDateOfTheCommission: 'Commission for admission of participants',
  applicationsForRegistrationAcceptedUntil:
    'Applications for registration of participants are accepted until',
  applicationsForRefereeingAreAcceptedUntil:
    'Applications for refereeing are accepted until',
  organisingCommittee: 'Organising сommittee',
  chiefJudge: 'Chief referee of the competition',
  chiefCompetitionSecretary: 'Chief Competition Secretary',
  chairman: 'Chairman of the Organizing Committee',
  organizingCommitteeAddress: 'Address of the organizing committee',
  theCommission: 'Commission for admission of participants',
  commissionStartDate: 'Commission start date',
  code: 'Code',
  administrator: 'Administrator',
  master: 'Organiser',
  queryType1: 'returns both users and their organizers',
  queryType2: 'returns only organizers',
  queryType3: 'returns only organizer users',
  recorder: 'Recorder',
  regions: 'Regions',
  cities: 'Cities',
  report: 'Report',
  achievements: 'Achievements',
  registrationOfJudges: 'Registration of judges for the tournament',
  declared: 'declared',
  accepted: 'accepted',
  submitApplication: 'Submit request',
  deleteApplication: 'Delete request',
  titleForReportsPortrait: 'Title for Reports (portrait)',
  titleForReportsLandscape: 'Title for Reports (landscape)',
  titleDescriptionPortrait:
    'Gives you the option to use a title for reports in portrait format',
  titleDescriptionLandscape:
    'Gives you the option to use a title for reports in landscape format',
  toggleReportHead: 'Disable report headers',
  go2Participants: 'Go to participants',
  recommendSize:
    'Recommended Pixel Image Resolution: {{size}} (Width X Height)',
  sportsCategory: 'Sports category',
  indicateSportsRank: 'Specify Sports Rank during registration',
  reference: 'Reference',
  qualJudgesRF: 'Qualification of judges of the Russian Federation',
  refereePositions: 'Referee Positions',
  categoryTypes: 'Category types',
  typeOfCategories: 'Category type',
  shortName: 'Abbreviation',
  boyGirl: 'Male, Female, Male, Female',
  girlBoy: 'Female, Male, Female, Male',
  boyBoy: 'Male, Male, Female, Female',
  girlGirl: 'Female, Female, Male, Male',
  groupBy: 'Group by: ',
  portraitPageOrientation: 'Portrait page',
  landscapePageOrientation: 'Landscape page',
  titleReport: 'Title of the Report',
  reportSettings: 'Report Settings',
  pageSettings: 'Page Settings',
  run: 'Run',
  turnOn: 'Turn on: ',
  listOfJudges: 'List of judges by tatami and teams',
  compositionOfTheJury: 'Composition of the Jury',
  judgeSignature: "Chief judge's signature",
  secretarySignature: "Chief secretary's signature",
  stampOnReports: 'Put a stamp on reports',
  signatureOnReports: 'Put a signature on reports',
  judgesForm: 'Judges',
  useQRcode: 'Use QR code when registering',
  registration: 'Participant Registration Settings',
  showIkoField: 'Specify IKO during registration',
  showQdanField: 'Specify Kyu/Dan during registration',
  showRegionField: 'Specify Region during registration',
  showClubField: 'Specify Club during registration',
  showCityField: 'Specify City during registration',
  showMiddleName: 'Specify Patronymic during registration',
  prohibitChangesAfterApproval:
    'Prohibit changes to an application after approval',
  preTournamentRegistration: 'Register before the tournament',
  importExcelHeader: 'Importing a list of participants from an Excel file',
  importSettingsFromOtherTournament: 'Import settings',
  importSettings: 'Import event settings',
  AreYouSureYouWantToImportSettings:
    'Are you sure you want to import settings from tournament {{from}} to tournament {{to}}?',
  info: 'Information',
  docIsBeingWritten: 'Documentation for this page is being written...',
  useAlternativeCategoryName: 'Use an alternative category name',
  eventInfo: 'Event information',
  registrarInfo: 'Team representative',
  enterValue: 'Enter value',
  selectValue: 'Select value',
  declaredWeight: 'Weight-1',
  sendingNotifications: 'Send alerts to Telegram bot',
  switchToParticipantMode: 'Switch to participant mode',
  switchToCategoryMode: 'Switch to category mode',
  participantMode: 'Participant mode',
  categoryMode: 'Category mode',
  deleteTatamiMsg: 'Are you sure you want to remove this Tatami?',
  categoriesWillBeMoved:
    'All categories will be moved to the nearest available tatami.',
  lastTatamiCannotBeRemoved: 'The last tatami cannot be removed!',
  tournamentMustHaveOneTatami: 'The tournament must have at least one tatami.',
  showRetired: 'Show Retired',
  listOfWinnersByCategory: 'List of winners by category',
  awardProcedure: 'Award procedure',
  registrarsCount: 'Number of registrars',
  creationDate: 'Date of creation',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  generateEmailToTournamentRegistrar:
    'After completing this action, an email will be sent to the organizer with a list of participants',
  showDeleted: 'Show deleted',
  assignParticipantTooltip: 'Assign participants to categories',
  assignToJudging: 'Assign to the judging',
  removeFromJudging: 'Remove from judging',
  help: 'Help',
  participantsStatisctics: 'Participants statistics',
  tatamiStatistics: 'Tatami statistics',
  statisticsByCategory: 'Statistics by category',
  statistics: 'Statistics',
  selectTatamiNames: 'Tatami name',
  englishABCD: 'A, B, C, D',
  russianABVG: 'А, Б, В, Г',
  numbersWithRoundBackground: '❶, ❷, ❸, ❹',
  numbersInCircle: '①, ②, ③, ④',
  roman: 'Ⅰ, Ⅱ, Ⅲ, Ⅳ',
  removeAppMsg:
    'Are you sure you want to uninstall this version of the application?',
  next: 'Next',
  back: 'Back',
  numberOfEntriesPerPage: 'Number of entries per page',
  eventAddress: 'Event location',
  addressCommission: 'Address of the commission for admission of participants',
  committeeEmail: 'Organizing committee email',
  fightFor3rdPlace: 'Fight for 3rd place',
  generalReportSettings: 'General report settings',
  reportSettings: 'Report settings',
  orientation: 'Page orientation',
  generalCategorySettings: 'General category settings',
  license: 'License',
  LicenseTournamentExpert:
    'License to use the Tournament Expert software package:',
  tourn: 'tournaments',
  noEvents: 'There are no planned events.',
  createEvent: 'Create an event',
  diploma: 'Diploma',
  invitationLink: 'Invitation Link',
  pincode: 'Event pin code',
  closeEvent: 'Close event',
  importParticipantsFromExcel: 'Importing participants from Excel file',
  assignToPosition: 'To appoint to the post',
  dateAndTimeOfTheEvent: 'Date and time of the event',
  categoryOpponents: 'Category opponents',
  addTeamRepresentative: 'Add Team Representative',
  applyFilter: 'Apply filter',
  ListOfAppliedTeamsforEvent:
    'List of team representatives who have applied for the event.',
  deleteAttachment: 'Delete document',
  teamRepresentativesOnly: 'Team Representatives only',
  teamRepresentatives: 'Team representatives',
  editAttachment: 'Edit document',
  addOrganisation: 'Add Organisation',
  RegisterOrganisationInTheAdmissionList:
    'Register the Organisation in the admission list',
  registrationList: 'Registration by list',
  editRep: 'Update Team Representative',
  title: 'Title',
  rate: 'Rating',
  hidePastTournaments: 'Hide past events',
  showPastTournaments: 'Show past events',
  userWrite: 'Write here',
  sendchatMessage: 'Send us a message',
  weightCategories: 'Weight Categories',
  regionalEvent: 'Regional event',
  internationalEvent: 'International event',
  historyTime: 'Messages are saved for 48 hours',
  replyTo: 'Replying to: ',
  constantName: 'Consultant',
  messagenoFound: 'Message not found',
  setPassword: 'Set password',
  fightsNumbering: 'Numbering of fights',
  loadingoldMessages: 'Loading...',
  loadpreviousMessages: 'Load previous messages',
  viewHistory: 'View History',
  representatives: 'Representatives',
  emailsDoNotMatch: 'Emails are different',
  documentNumber: 'Document Number',
  gridorderCategory: 'Sort by age',
  disableNumberingOfFights: 'Disable numbering of fights',
  athleteName: 'Athlete name',
  trainerName: 'Trainer name',
  empty: 'Empty',
  showDocNumField: 'Specify Document Number when registering'
};
