import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  gridRecordsAttachments: {
    '@media (min-width:1024px)': {
      gridTemplateColumns: '25em 1fr'
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      gridTemplateColumns: '15em 1fr'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  btnDownloadDoc: {
    width: '10.715em'
  },

  docIc: {
    height: 'auto',
    width: '5rem'
  },

  center: {
    textAlign: 'center'
  },

  actionIcDoc: {
    width: '3em',
    height: '3em'
  },

  cardWrapper: {
    backgroundColor: '#FFFFFF',
    gridTemplateColumns: 'repeat(4, max-content)',
    padding: '0 0 0.5rem 0.5rem'
  },

  txtWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    wordSpacing: '100vw',
    '@media (max-width: 767px)': {
      fontWeight: 600,
      wordSpacing: 'normal'
    }
  }
});

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width: 1201px)': {
        display: 'grid',
        gridTemplateColumns: props?.map((it) => it.width ?? '1fr').join(' '),
        gap: '0.5rem'
      },
      '@media (max-width:1200px) and (min-width:480px)': {
        gridTemplateColumns: '1fr 1fr 1fr'
      },
      '@media (max-width:479px) and (min-width:300px)': {
        gridTemplateColumns: '1fr 1fr'
      }
    };
  }
}));
